<template>
  <div>
    <sentItemList v-if="$store.state.productState.showSendItemsList" class="sent-item-list-wrapper open"
      :class="completedItemsEqualsSelectedItems ? 'sent-item-list-wrapper open' : 'sent-item-list-wrapper'" />
    <div id="progress-popup" :class="{ 'progress-popup': true, 'openProgress': visible }">
      <div class="progress-popup-box">
        <div class="tag-label">Item Sending to Sitecore</div>
        <!-- <div class="progress-close" @click="closeProgressPopup" @keydown.enter="closeProgressPopup">close <img
            src="../assets/cartview-close.svg" alt="close-icon" /></div> -->
        <div class="wrap"
          v-if="$store.state.productState.sendItemsList && $store.state.productState.sendItemsList.length !== 0">
          <div class="progress-item-box" v-for="(selectedItem, index) in $store.state.productState.sendItemsList"
            :key="index">
            <div class="box-cover">
              <div class="progress-image">
                <!-- <img :src="getImageUrl(selectedItem)" :alt="selectedItem.imageAlt" /> -->
                <img :src="selectedItem.image" @error="handleImageError" :alt="selectedItem.imageAlt" />
              </div>
              <div class="progress-content">
                <h4>{{ selectedItem.title }}</h4>
                <div class="progress-bar" :class="{
                  'queue': selectedItem.status === 1,
                  'inprogress': selectedItem.status === 2,
                  'completed': selectedItem.status === 3,
                  'failed': selectedItem.status === 4
                }">
                  <div class="status">
                    <span v-if="selectedItem.status === 2">In progress</span>
                    <span v-else-if="selectedItem.status === 3">Completed</span>
                    <span v-else-if="selectedItem.status === 4">Failed</span>
                    <span v-else>Queue</span>
                  </div>
                  <div class="progress">
                    <div class="progress-done"
                      :style="'width: ' + (selectedItem.status === 1 ? '10%' : selectedItem.status === 2 ? '100%' : selectedItem.status === 3 ? '100%' : '100%')">
                    </div>
                  </div>
                  <div class="file-name" v-if="selectedItem.guid !== null && selectedItem.status === 3">
                    <p>{{ selectedItem.guid }}
                      <span class="copy" @click="copyToClipboard(selectedItem.guid)"
                        @keydown.enter="copyToClipboard(selectedItem.guid)" tabindex="0">
                        <img src="../assets/copy.svg" alt="copy_image">Copy
                      </span>
                    </p>
                    <p v-if="copied" class="copied-message">Copied!</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="popup-box-footer">
          <div class="total-progress-col">
            <div class="progress-col">
              <div class="progress-bar inprogress">
                <div class="status">Item Sending Status</div>
                <div class="progress">
                  <div class="progress-done" :style="{ width: progressBarWidth }"></div>
                </div>
                <div class="status-content">
                  <p>Completed {{ $store.state.productState.completedItems }} / {{
                    $store.state.productState.sendItemsList.length }}</p>
                </div>
              </div>
            </div>
            <div class="button-col">
              <button type="button" class="cancel" @click.prevent="showCancelPopup" @keydown.enter="showCancelPopup"
                tabindex="0">cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cancelPopup :visible="$store.state.productState.showCancelPopup" @close="closeProgressPopup" />
  </div>
</template>
<script>
import { computed } from 'vue';
// import { store } from 'vuex';

import sentItemList from './sentItemList.vue';
import cancelPopup from './cancelPopup.vue';

export default {
  name: 'progressPopup',
  // store,
  props: {
    visible: Boolean,
  },
  components: {
    sentItemList,
    cancelPopup,
  },
  data() {
    return {
      showCancel: false,
      copied: false,
      selectedItem: {
        is_success: true,
        is_in_progress: false,
        is_completed: true,
        progressPercentage: 30,
      },
      fallbackImgUrl: '../assets/placeholder-image.svg',
    };
  },
  computed: {
    selectedItems() {
      return computed(() => this.$store.state.productState.selectedItems);
    },
    progressBarWidth() {
      const { completedItems } = this.$store.state.productState;
      const totalItems = this.$store.state.productState.sendItemsList.length;
      const percentage = (completedItems / totalItems) * 100;
      return `${percentage}%`;
    },
    completedItemsEqualsSelectedItems() {
      const { completedItems } = this.$store.state.productState;
      const { failedItems } = this.$store.state.productState;
      const totalItems = completedItems + failedItems;
      const selectedItemsLength = this.$store.state.productState.sendItemsList.length;
      return totalItems === selectedItemsLength;
    },
  },
  methods: {
    closeProgressPopup() {
      this.$emit('close');
      this.$store.commit('resetPauseDataTransfer');
    },
    getImageUrl(product) {
      if (product.image && !this.isInvalidImageUrl(product.image)) {
        return product.image;
      }
      return '../assets/placeholder-image.svg';
    },
    isInvalidImageUrl(url) {
      return !url.endsWith('.png');
    },
    handleImageError(event) {
      // Change the src attribute to the fallback image URL
      const imgElement = event.target;
      imgElement.src = this.fallbackImgUrl;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          // console.log('Text copied to clipboard:', text);
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        })
        .catch((error) => {
          console.error('Failed to copy text:', error);
        });
    },
    showCancelPopup() {
      this.showCancel = true;
      this.$store.commit('showCancelPopup');
    },
  },
};
</script>
<style scoped lang="scss">
.progress-popup {
  // display: flex;
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(88, 88, 88, 0.75);
  backdrop-filter: blur(4px);
  padding: 15px;
  z-index: 999;

  &.openProgress {
    display: flex;

    .progress-popup-box {
      display: flex;
      animation: fade-in 0.3s ease-in-out;
    }
  }
}

.progress-close {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 15px;
  right: 22px;
  z-index: 2;
  align-items: center;
  line-height: 0;
  cursor: pointer;
}

.progress-close img {
  margin-left: 7px;
}

.tag-label {
  display: block;
  width: 100%;
  max-width: 300px;
  background: #000;
  padding: 15px;
  border-bottom-right-radius: 20px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 20px;
  font-family: "gill_sans_mtregular";
  font-weight: 400;
  line-height: 16px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-bottom: 155px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  margin-bottom: 28px;
  margin-top: 16px;

  @media(max-width:768px) {
    max-height: calc(100vh - 100px);
    padding-bottom: 110px;
  }
}

.progress-popup-box {
  display: none;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 800px;
  background: #e9e9e9;
  padding: 60px 15px 0px;
}

.progress-item-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.box-cover {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  max-width: 660px;
  padding: 28px 30px;
  background: #fff;

  @media(max-width:575px) {
    padding: 15px 10px;
  }

  .progress-image {
    width: 100%;
    max-width: 106px;
    display: flex;
    flex-wrap: wrap;
  }

  .progress-content {
    width: 100%;
    max-width: calc(100% - 106px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;

    @media(max-width:575px) {
      max-width: calc(100% - 0px);
    }

    h4 {
      font-size: 14px;
      font-family: "gill_sans_mtregular";
      line-height: 25px;
      margin-bottom: 7px;
    }
  }

  .file-name {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    margin: 10px 0px;

    p {
      margin-bottom: 0px;
      width: 100%;
      color: #8A4B4B;
      font-size: 12px;
      font-family: "gill_sans_mtregular";
      line-height: 24.621px;
      letter-spacing: 0.36px;

      span {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border: 0.3px solid #ccc;
        color: #000;
        padding: 3px 11px 3px;
        border-radius: 50px;
        margin-left: 10px;
        color: #101010;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        line-height: 39.314px;
        letter-spacing: 0.246px;
        font-family: "gill_sans_mtregular";
        line-height: 15px;
        cursor: pointer;

        img {
          margin-right: 5px;
          margin-top: -1px;
        }
      }
    }
  }

}

.status {

  font-size: 12px;
  font-family: "gill_sans_mtregular";
  line-height: 22.314px;
  letter-spacing: 0.246px;
}

.progress {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  height: 8px;
  background: #ccc;
  border-radius: 20px;
}

.progress-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.progress-done {
  border-radius: 20px;
  // border-bottom-right-radius: 0px;
  // border-top-right-radius: 0px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  transition: 1s ease 0.3s;
  opacity: 1;
}

.completed {
  .status {
    color: #40AA87;
  }

  .progress-done {
    background: #40AA87;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.inprogress {
  .status {
    color: #6A96EC;
  }

  .progress-done {
    background: #6A96EC;
    animation: change 1s linear infinite;
    background: linear-gradient(-45deg,
        rgba(255, 255, 255, 0.15) 25%, transparent 25%,
        transparent 50%, rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%, transparent 75%) left/30px 30px repeat-x,
      linear-gradient(to right, #6A96EC 0%, #6A96EC 50%, #6A96EC 100%) left/var(--p, 100%) fixed,
      lightgray;
  }
}

@keyframes change {
  from {background-position:0 0,left}
  to {background-position:30px 0,left}
}

.queue {
  .status {
    color: #E58D4D;
  }

  .progress-done {
    background: #E58D4D;
  }
}

.failed {
  .status {
    color: red;
  }

  .progress-done {
    background: red;
  }
}

.popup-box-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  position: relative;
  background: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 33px 51px;
  position: absolute;
  left: 0;
  bottom: 0;

  @media(max-width:767px) {
    padding: 10px 20px;
  }

  .total-progress-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .progress-col {
    display: flex;
    flex-wrap: wrap;
    width: 67%;

    @media(max-width:767px) {
      width: 50%;
    }

    .status {
      margin-bottom: 5px;
      color: #000;
      font-size: 16px;
      font-family: "gill_sans_mtregular";
      font-weight: 600;
      line-height: 25px;
    }

    .progress {
      height: 17px;
      background: #D7EAE0;
      margin-bottom: 10px;

      @media(max-width:768px) {
        height: 8px;
      }
    }

    .progress-done {
      background: #40AA87;
    }
  }

  .button-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 32%;
    padding-left: 20px;

    @media(max-width:767px) {
      width: 50%;
    }

    .cancel {
      padding: 10px 10px;
      width: 100%;
      max-width: 145px;
      border: 1px solid #000;
      color: #000;
      font-size: 18px;
      font-family: "gill_sans_mtregular";
      background: transparent;
      border: 1px solid #000;
      cursor: pointer;
    }
  }

  .status-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    color: #000;
    font-size: 10px;
    font-family: "gill_sans_mtregular";
    font-weight: 600;
  }
}

.copied-message {
  color: #03A14C;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}</style>
