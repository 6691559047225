<template>
  <div>
    <div id="cancelpopup" class="cancelpopup" :class="{ 'open': visible }">
      <div class="cancelpopup-wrapper">
        <div class="cancelpopup-content">
          <div class="cancelpopup-head">
            <h3>Are you sure?</h3>
            <p>
              All your changes will be
              <span>Cancel</span> and you will no longer be able to access them
            </p>
          </div>
          <div class="cancelpopup-button">
            <div class="cancel-btn">
              <button class="popupcancel" @click="cancelClick" @keydown.enter="cancelClick" tabindex="0">Cancel</button>
            </div>
            <div class="confirm-btn">
              <button class="popupconfirm" @click="sureBtnClick" @keydown.enter="sureBtnClick" tabindex="0">Yes, I'm
                sure</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'cancelPopup',
  data() {
    return {
      showClass: false,
    };
  },
  props: {
    visible: Boolean,
  },
  methods: {
    sureBtnClick() {
      this.$store.commit('filterCompletedItems');
      this.$store.commit('showSentItemList');
      this.$store.commit('hideProgressPopupForCancel');
      // this.$store.commit('resetPauseDataTransfer');

      this.$store.dispatch('productInitialList', {
        searchKeyword: this.$store.state.productState.searchTerm,
        selectOptionZero: this.$store.state.productState.collection_ids !== null && this.$store.state.productState.collection_ids[0] !== null ? [this.$store.state.productState.collection_ids[0]] : [],
        selectOptionOne: this.$store.state.productState.group_ids !== null && this.$store.state.productState.group_ids[0] !== null ? [this.$store.state.productState.group_ids[0]] : [],
        selectOptionTwo: this.$store.state.productState.subgroup_ids !== null && this.$store.state.productState.subgroup_ids[0] !== null ? [this.$store.state.productState.subgroup_ids[0]] : [],
        selectOptionThree: this.$store.state.productState.category_ids !== null && this.$store.state.productState.category_ids[0] !== null ? [this.$store.state.productState.category_ids[0]] : [],
        selectOptionFour: this.$store.state.productState.shell_colors !== null && this.$store.state.productState.shell_colors[0] !== null ? [this.$store.state.productState.shell_colors[0]] : [],
        selectOptionFive: this.$store.state.productState.leg_colors !== null && this.$store.state.productState.leg_colors[0] !== null ? [this.$store.state.productState.leg_colors[0]] : [],
        selectOptionSix: this.$store.state.productState.upholsteries !== null && this.$store.state.productState.upholsteries[0] !== null ? [this.$store.state.productState.upholsteries[0]] : [],
        selectOptionSeven: this.$store.state.productState.cont_upholsteries !== null && this.$store.state.productState.cont_upholsteries[0] !== null ? [this.$store.state.productState.cont_upholsteries[0]] : [],
        selectOptionEight: this.$store.state.productState.config_groups !== null && this.$store.state.productState.config_groups[0] !== null ? [this.$store.state.productState.config_groups[0]] : [],
        selectedPage: this.$store.state.productState.selectedPage,
      });
      this.$store.commit('pageChangeFeature', 1);
      document.body.classList.remove('o-hidden');
    },
    cancelClick() {
      this.$store.commit('hideCancelpopup');
      this.$store.dispatch('sendToSitecore');
      document.body.classList.remove('o-hidden');
    },
  },
  // computed: {
  //   completedItemsGreaterSelectedItems() {
  //   },
  // },
};
</script>
<style scoped lang="scss">
.cancelpopup {
  // display: flex;
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(88, 88, 88, 0.75);
  backdrop-filter: blur(4px);
  padding: 15px;
  z-index: 9999;

  &.open {
    display: flex;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 654px;
    height: auto;
    padding: 30px 40px;
    background: #fff;
    min-height: 279px;
    align-items: center;
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
  }

  &-head {
    display: block;
    width: 100%;
    max-width: 100%;
    position: relative;

    h3 {
      margin-bottom: 11px;
      color: #000;
      font-size: 24px;
      font-family: "gill_sans_mtregular";
      font-weight: 700;
      line-height: 33px;
      display: block;
    }

    p {
      margin-bottom: 4px;
      color: #000;
      font-weight: 400;
      font-size: 17px;
      font-family: "gill_sans_mtregular";
      line-height: 24px;

      span {
        font-weight: 700;
        color: #f22f2a;
      }
    }
  }

  &-button {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    // max-width: 100%;
    position: relative;
    margin: 0px -15px 0px;
    width: 100%;

    @media (max-width: 410px) {
      margin: 20px 0px 20px;
    }

    .cancel-btn {
      display: block;
      width: 100%;
      max-width: 164px;
      margin: 15px 15px 0px;

      @media (max-width: 575px) {
        max-width: calc((100% / 2) - 30px);
      }

      @media (max-width: 410px) {
        max-width: calc((100% / 1) - 30px);
      }

      button {
        padding: 20px 15px;
        background: #fff;
        color: #000;
        border: 0px;
        border: 1px solid #000;
        box-shadow: 0px 0px 0px;
        width: 100%;
        cursor: pointer;
      }
    }

    .confirm-btn {
      display: block;
      width: 100%;
      max-width: 186px;
      margin: 15px 15px 0px;

      @media (max-width: 575px) {
        max-width: calc((100% / 2) - 30px);
        //   max-width:42%;
      }

      @media (max-width: 410px) {
        max-width: calc((100% / 1) - 30px);
      }

      button {
        background: #000;
        color: #fff;
        border: 1px solid transparent;
        box-shadow: 0px 0px 0px;
        width: 100%;
        padding: 20px 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
