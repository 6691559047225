<template>
  <div id="productPopup" :class="{ 'productPopup': true, 'popup-open': visible }">
    <div class="productPopup-content--wrapper">
      <div class="published-icon green" v-if="product && product.is_published && product.is_sent">
        <p>Item Published</p>
      </div>
      <div class="published-icon orange" v-if="product && product.is_sent && !product.is_published">
        <p>Item Unpublished</p>
      </div>
      <div class="productpopup-close">
        <span @click="ProductClose" @keydown="ProductClose">
          close
          <img src="../assets/cartview-close.svg" alt="close-icon" />
        </span>
      </div>
      <div class="productPopup__image-block">
        <img :src="product && product.image" @error="handleImageError" :alt="product && product.imageAlt" />
      </div>
      <div class="productPopup__detail-block">
        <div class="product-popup-content">
          <h2>
            {{ product && product.title }}
          </h2>
          <p>{{ product && product.guid }}</p>
          <div class="detailedcontent-two-col">
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Config Title</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.config_group_title }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>SKU</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.art_no }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Group ID</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.group_id }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Shell</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.shell_nav_id }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Upholstery</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.upholstery_nav_id }} <span v-if="product && product.upholstery_nav_id">-</span> {{ product && product.upholstery_supplier_id }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Legs</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.leg_nav_id }} <span v-if="product && product.leg_nav_id">-</span> {{ product && product.leg_supplier_id }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>Piping & Cushions</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.piping_and_cushion_nav_id }} <span v-if="product && product.piping_and_cushion_nav_id">-</span> {{ product &&
                  product.piping_and_cushion_supplier_id }}</p>
              </div>
            </div>
            <div class="repeated-block">
              <div class="detailedcontent-left">
                <p>EAN</p>
              </div>
              <div class="detailedcontent-right">
                <p><span>:</span> {{ product && product.ean }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productPopup',
  props: {
    visible: Boolean,
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fallbackImgUrl: '../assets/placeholder-image.svg',
    };
  },
  methods: {
    ProductClose() {
      this.$emit('close');
    },
    handleImageError(event) {
      // Change the src attribute to the fallback image URL
      const imgElement = event.target;
      imgElement.src = this.fallbackImgUrl;
    },
  },
  computed: {
    popupClasses() {
      return {
        popup: true,
        show: this.visible,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.productPopup {
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 15px;
  overflow: hidden;
  background: rgba(88, 88, 88, 0.75);
  backdrop-filter: blur(4px);
  z-index: 999;

  &.popup-open {
    display: flex;

    .productPopup-content--wrapper {
      display: flex;
      animation: fade-in 0.3s ease-in-out;
    }
  }

  .productpopup-close {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 10px;
    right: 19px;
    align-items: center;
    cursor: pointer;

    span {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #060606;
      font-size: 12px;
      font-weight: 400;
      line-height: 29.27px;

      img {
        padding-left: 5px;
        width: 19px;
        height: 19px;
      }
    }
  }

  &-content--wrapper {
    display: none;
    flex-wrap: wrap;

    position: relative;
    width: 100%;
    max-width: 764px;
    background: #fff;
    padding: 65px 15px 40px;
  }

  .published-icon {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 30px;

    align-items: center;
    position: absolute;
    border-bottom-right-radius: 15px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "gill_sans_mtregular";
    width: 208px;
    justify-content: center;
    // font-weight: 600;
    line-height: 16px;
    top: 0;
    left: 0;

    &.green {
      background: #18A661;
    }

    &,
    orange {
      background-color: #FF5858;
    }

    p {
      margin-bottom: 0px;
    }
  }

  &__image-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 387px;
    justify-content: center;
    align-items: center;

    @media(max-width:768px) {
      max-width: 200px;
    }

    @media(max-width:575px) {
      margin: 0 auto;
    }

    img {
      display: block;
      width: auto;
      max-width: 100%;
      //   max-width: 260px;
    }
  }

  &__detail-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    max-width: calc(100% - 387px);
    padding-left: 15px;

    @media(max-width:768px) {
      max-width: calc(100% - 200px);
      padding-left: 50px;
    }

    @media(max-width:575px) {
      max-width: calc(100% - 0px);
      padding-left: 0px;

    }

    .product-popup-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;

      h2 {
        color: #000;
        font-size: 18px;
        font-family: "gill_sans_mtregular";
        font-weight: 600;
        line-height: 25px;
        margin: 0px 0px 5px;
      }

      p {
        color: #8a4b4b;
        font-size: 12px;
        font-family: "gill_sans_mtregular";
        line-height: 24.621px;
        letter-spacing: 0.36px;
        margin: 0px 0px;
      }
    }
  }

  .detailedcontent-two-col {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 5px;

    .repeated-block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;
      position: relative;
    }

    .detailedcontent-left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 50%;
      padding-left: 0px;

      p {
        color: #060606;
        font-size: 16px;
        font-family: "gill_sans_mtregular";
        font-weight: 300;
        line-height: 29.27px;
      }
    }

    .detailedcontent-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 50%;
      padding-right: 0px;

      p {
        color: #060606;
        font-size: 16px;
        font-family: "gill_sans_mtregular";
        line-height: 29.27px;
        font-weight: 600;
        span {
          font-weight: 300;
        }
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}</style>
