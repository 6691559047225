<template>
  <HeaderNav />
  <div class="signup-form-container">
    <h2>Register User</h2>
    <form class="signup-form" @submit.prevent="submitForm">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="formData.username" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="formData.password" required />
      </div>
      <div class="form-group">
        <label for="retypePassword">Retype Password:</label>
        <input type="password" id="retypePassword" v-model="formData.retypePassword" required />
      </div>
      <div class="form-group">
        <label for="role">Role:</label>
        <select id="role" v-model="formData.role" required>
          <option value="">Select Role</option>
          <option v-for="option in roleOptions" :key="option.id" :value="option.id">
            {{ option.role }}
          </option>
        </select>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import HeaderNav from "../components/Header.vue";
import { ref, onMounted } from "vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "AdminPage",
  components: {
    HeaderNav,
  },
  data() {
    return {
      formData: {
        username: "",
        password: "",
        retypePassword: "",
        role: "",
      },
      roleOptions: [], // Array to store dropdown options
    };
  },
  mounted() {
    this.fetchRoleOptions();
  },
  methods: {
    async fetchRoleOptions() {
      const API_KEY = "6548454121546548764564457464854546";
      const response = await axios.get(
        `https://configurator-api.normann-copenhagen.com/api/dash-board/get-admin-details`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-API-Key": API_KEY,
          },
        }
      );

      var responseData = response.data;
      this.roleOptions = responseData;
    },
    submitForm() {
      // Handle form submission logic here

      const data = JSON.stringify({
        email: this.formData.username,
        password: this.formData.password,
        role: this.formData.role,
      });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://configurator-api.normann-copenhagen.com/api/dash-board/register-user",
        headers: {
          "X-API-Key": "6548454121546548764564457464854546",
          "Content-Type": "application/json",
        },
        data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response && response.data.is_success) {
            this.formData.username = "";
            this.formData.password = "";
            this.formData.role = "";
            this.formData.retypePassword = "";
            alert("User registered successfully");
          } else {
            console.log("failed");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.signup-form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  position: relative;
  top: 200px;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="password"],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}
</style>
