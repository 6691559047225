<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.container-row {
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
</style>
