<template>
  <div class="products">
    <div class="top-row" :class="{ scrolled: isScrolled }">
      <div class="product-count">
        <!-- <p>Showing total <span>- 35/50 items</span></p> -->
        <p>Showing total - <span>{{ displayRange }}/{{ productsLength }} items</span></p>
      </div>
      <div class="sort">

        <div class="custom-select">
          <div class="select-box" @click="toggleDropdown(0)" @keydown.enter="toggleDropdown(0)" tabindex="0">
            <div class="selected-item">{{ selectedOption[0] || options[0][0] }}</div>
            <span class="label-name">Filter by</span>
            <div class="arrow" :class="{ open: isDropdownOpen[0] }"></div>
          </div>
          <ul class="dropdown-menu" v-show="isDropdownOpen[0]">
            <li v-for="option in options[0]" :key="option" @click="selectOption(0, option)"
              @keydown.enter="selectOption(0, option)" tabindex="0">
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
      <div class="grid-list-view">
        <span @click="toggleView" @keydown.enter="toggleView" tabindex="0">
          <img :src="viewIconSrc" alt="view">
        </span>
      </div>
    </div>
    <div :class="viewMode === 'grid' ? 'product-listing grid-view' : 'product-listing list-view'">
      <div class="list-heading" v-if="$store.state.productState.products.length !== 0">
        <div class="action">
          <p>Action</p>
        </div>
        <div class="productName">
          <p>Product Name</p>
        </div>
        <div class="sku">
          <p>SKU</p>
        </div>
        <div class="upholstery">
          <p>Upholstery</p>
        </div>
        <div class="pipingCushions">
          <p>Piping & Cushions</p>
        </div>
      </div>
      <div class="product-listing-wrap" v-if="$store.state.productState.products.length !== 0 && isLoading === false">
        <div class="product-col" v-for="(product, index) in $store.state.productState.products" :key="index">
          <!-- <div class="ribbon published">
            <span>Published</span>
          </div> -->
          <div class="ribbon">
            <span v-if="product.is_sent"
              :class="['ribbon-b2c', { 'published': product.is_sent && product.is_published, 'unpublished': product.is_sent && !product.is_published }]">{{
                product.is_sent && product.is_published ? 'B2C' : 'B2C' }}</span>
            <span v-if="product.is_b2b_sent"
              :class="['ribbon-b2b', { 'published': product.is_b2b_sent && product.is_b2b_published, 'unpublished': product.is_b2b_sent && !product.is_b2b_published }]">{{
                product.is_b2b_published && product.is_b2b_sent ? 'B2B' : 'B2B' }}</span>
            <span v-if="product.is_datasheet_sent"
              :class="['ribbon-pds', { 'published': product.is_datasheet_sent && product.is_datasheet_published, 'unpublished': product.is_datasheet_sent && !product.is_datasheet_published }]">{{
                product.is_datasheet_published && product.is_datasheet_sent ? 'PDS' : 'PDS' }}</span>
          </div>
          <!-- <div class="checkbox" :class="{ checked: product.checked }" @click="toggleCheckbox(index)"
            @keydown.enter="toggleCheckbox(index)" tabindex="0"></div> -->
          <div class="checkbox" :class="{
            checked: $store.state.productState.selectedItems.filter(x =>
              x.item_id == product.item_id
              // && x.leg_item_id === product.leg_item_id
              // && x.upholstery_item_id === product.upholstery_item_id
              // && x.shell_item_id === product.shell_item_id
              // && x.piping_and_cushion_item_id === product.piping_and_cushion_item_id
              // && x.guid === product.guid
              && x.piping_and_cushion_nav_id == product.piping_and_cushion_nav_id
              && x.piping_and_cushion_supplier_id == product.piping_and_cushion_supplier_id
              && x.art_no === product.art_no
              // && x.config_group_title === product.config_group_title
              // && x.ean === product.ean
              && x.group_id === product.group_id
              && x.image === product.image
              // && x.is_published === product.is_published
              // && x.is_sent === product.is_sent
              && x.leg_nav_id === product.leg_nav_id
              && x.leg_supplier_id === product.leg_supplier_id
              && x.shell_nav_id === product.shell_nav_id
              && x.shell_supplier_id === product.shell_supplier_id
              && x.title === product.title
              && x.upholstery_nav_id === product.upholstery_nav_id
              && x.upholstery_supplier_id === product.upholstery_supplier_id
            ).length > 0
          }" @click="toggleCheckbox(index)" @keydown.enter="toggleCheckbox(index)" tabindex="0"></div>
          <div class="image">
            <img :src="product.image" @error="handleImageError" :alt="product.imageAlt" />
          </div>
          <div class="content" @click="openProductPopup(product)" @keydown.enter="openProductPopup(product)" tabindex="0">
            <h6 class="title">{{ product.title }} <span v-if="product.is_sent"
                :class="['ribbon', { 'published': product.is_sent && product.is_published, 'unpublished': product.is_sent && !product.is_published }]">{{
                  product.is_sent && product.is_published ? 'B2C' : 'B2C' }}</span>
              <span v-if="product.is_b2b_sent"
                :class="['ribbon', { 'published': product.is_b2b_sent && product.is_b2b_published, 'unpublished': product.is_b2b_sent && !product.is_b2b_published }]">{{
                  product.is_b2b_sent && product.is_b2b_published ? 'B2B' : 'B2B' }}</span>
              <span v-if="product.is_datasheet_sent"
                :class="['ribbon', { 'published': product.is_datasheet_sent && product.is_datasheet_published, 'unpublished': product.is_datasheet_sent && !product.is_datasheet_published }]">{{
                  product.is_datasheet_sent && product.is_datasheet_published ? 'PDS' : 'PDS' }}</span>
            </h6>
            <p>
              <span v-if="product.upholstery_supplier_id">Upholstery</span>
              <span v-if="product.upholstery_supplier_id">: {{ product.upholstery_nav_id }} - {{
                product.upholstery_supplier_id
              }}</span>
            </p>
            <p>
              <span v-if="product.piping_and_cushion_supplier_id">Piping & Cushions</span>
              <span v-if="product.piping_and_cushion_supplier_id">: {{ product.piping_and_cushion_nav_id }} - {{
                product.piping_and_cushion_supplier_id }}</span>
            </p>
            <h6 v-if="product.art_no" class="product-number">{{ product.art_no }}</h6>
          </div>
        </div>
      </div>
      <div v-else class="empty-cart">
        <div class="empty-cart-wrap">
          <div class="image">
            <img src="../assets/empty-cart.png" alt="Empty cart">
          </div>
          <div class="content">
            <h2>No items Found</h2>
            <p>We can’t find any item maching your search</p>
          </div>
        </div>
      </div>
      <div class="loader-wrap" v-if="isLoading">
        <div class="loader"></div>
      </div>
    </div>
    <div v-if="$store.state.productState.pages > 1" class="pagination">
      <ul>
        <li v-if="showPrevArrow" class="prev-page" @click="prevPage" @keydown.enter="prevPage" tabindex="0">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.64746 12.2841L6.95996 6.97162L1.64746 1.65912" stroke="#474747" stroke-width="1.93182"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </li>
        <li v-for="page in visiblePages" :key="page" :class="{ active: page === $store.state.productState.selectedPage }"
          @click="changePage(page)" @keydown.enter="changePage(page)" tabindex="0">
          {{ page }}
        </li>
        <li v-if="showNextArrow" class="next-page" @click="nextPage" @keydown.enter="nextPage" tabindex="0">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.64746 12.2841L6.95996 6.97162L1.64746 1.65912" stroke="#474747" stroke-width="1.93182"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </li>
      </ul>
    </div>
    <productPopup :visible="showProduct" :product="selectedProduct" @close="ProductClose" />
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
// import axios from 'axios';
// import { mapState } from 'vuex';
// import products from '../store/modules/ProductList';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import productState from '../store/modules/ProductList';

import productPopup from './productPopup.vue';

// const API_KEY = '6548454121546548764564457464854546';
// const proxyurl = 'https://cors-anywhere.herokuapp.com/';

export default {
  name: 'ProductPage',
  // store,
  components: {
    productPopup,
  },
  setup() {
    const options = [
      ['All items', 'B2C Published', 'B2C Unpublished', 'B2B Published', 'B2B Unpublished', 'Data Sheet Unpublished', 'Data Sheet Published'],
    ];
    const selectedOption = ref(['', '', '', '']);
    const isDropdownOpen = ref([false, false, false, false]);
    let activeDropdown = null;

    function toggleDropdown(index) {
      if (activeDropdown !== null && activeDropdown !== index) {
        isDropdownOpen.value[activeDropdown] = false;
      }
      isDropdownOpen.value[index] = !isDropdownOpen.value[index];
      activeDropdown = isDropdownOpen.value[index] ? index : null;
    }

    const store = useStore();
    const loading = ref(false);
    async function filterLoadedData() {
      loading.value = true;

      try {
        const selected = selectedOption.value[0];
        let filter = '';
        if (selected === 'B2C Published') {
          filter = 'published';
        } else if (selected === 'B2C Unpublished') {
          filter = 'sent';
        } else if (selected === 'All items') {
          filter = '';
        } else if (selected === 'B2B Published') {
          filter = 'b2bpublished';
        } else if (selected === 'B2B Unpublished') {
          filter = 'b2bsent';
        } else if (selected === 'Data Sheet Unpublished') {
          filter = 'datasheetsent';
        } else if (selected === 'Data Sheet Published') {
          filter = 'datasheetpublished';
        }
        store.commit('setSelected', selected);
        store.commit('setFilter', filter);
        const payload = {
          searchKeyword: store.state.productState.searchTerm,
          selectOptionFour: store.state.productState.collection_ids && store.state.productState.collection_ids.length > 0 ? store.state.productState.collection_ids : [],
          selectOptionFive: store.state.productState.group_ids && store.state.productState.group_ids.length > 0 ? store.state.productState.group_ids : [],
          selectOptionSix: store.state.productState.subgroup_ids && store.state.productState.subgroup_ids.length > 0 ? store.state.productState.subgroup_ids : [],
          selectOptionSeven: store.state.productState.category_ids && store.state.productState.category_ids.length > 0 ? store.state.productState.category_ids : [],
          selectOptionThree: store.state.productState.shell_colors && store.state.productState.shell_colors.length > 0 ? store.state.productState.shell_colors : [],
          selectOptionOne: store.state.productState.leg_colors && store.state.productState.leg_colors.length > 0 ? store.state.productState.leg_colors : [],
          selectOptionZero: store.state.productState.upholsteries && store.state.productState.upholsteries.length > 0 ? store.state.productState.upholsteries : [],
          selectOptionTwo: store.state.productState.cont_upholsteries && store.state.productState.cont_upholsteries.length > 0 ? store.state.productState.cont_upholsteries : [],
          selectOptionEight: store.state.productState.config_groups && store.state.productState.config_groups.length > 0 ? store.state.productState.config_groups : [],
          selectedPage: 1,
          filter,
        };
        await store.dispatch('productInitialList', payload);
        loading.value = false;
        store.commit('pageChangeFeature', 1);
      } catch (error) {
        // this.$store.commit('pageChangeFeature', 1);
        // store.commit('setLoading', true);
        // setTimeout(() => {
        //   // console.log('loader');
        //   store.commit('setLoading', false);
        // }, 3000);
        loading.value = false;
      }
    }

    function selectOption(index, option) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      selectedOption.value[index] = option;
      isDropdownOpen.value[index] = false;
      filterLoadedData();
    }

    // Close dropdown when clicking outside
    function closeDropdowns(event) {
      const { target } = event; // Element that triggered the event
      const dropdowns = event.currentTarget.querySelectorAll('.custom-select');

      // Check if the target element or its parents have the class name "custom-select"
      const isInsideCustomSelect = Array.from(dropdowns).some((dropdown) => dropdown.contains(target));

      // If not inside custom select, close dropdowns
      if (!isInsideCustomSelect) {
        isDropdownOpen.value = [false, false, false, false];
        activeDropdown = null;
      }
    }

    onMounted(() => {
      // filterLoadedData();
      // Add event listener to the document for click events
      document.addEventListener('click', closeDropdowns);
    });

    return {
      options,
      selectedOption,
      isDropdownOpen,
      toggleDropdown,
      selectOption,
      // products,
    };
  },

  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // console.log(this.$store.state.productState.products);
    // this.$store.dispatch('productInitialList', '607050');
    setInterval(() => {
      const paragraphs = document.querySelectorAll('.list-view .product-col p');
      // eslint-disable-next-line
      paragraphs.forEach((paragraph) => {
        const spanElement = paragraph.querySelector('span:nth-child(2)');
        if (spanElement) {
          const originalText = spanElement.textContent;

          const modifiedText = originalText.replace(/:/g, '');

          spanElement.textContent = modifiedText;
        }
      });
    }, 10);
    // this.updateText('grid-view');

    // Retrieve selected items from localStorage
    const userId = Cookies.get('user_id');
    const cartDataJSON = localStorage.getItem('cartItems');
    if (cartDataJSON) {
      const cartData = JSON.parse(cartDataJSON);
      if (userId in cartData) {
        const selectedItems = cartData[userId];
        this.$store.commit('initializeSelectedItems', selectedItems);
      } else {
        const selectedItems = [];
        this.$store.commit('initializeSelectedItems', selectedItems);
      }
    }

    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    productsLength() {
      const totalHits = this.$store.state.productState.total_hits;

      if (totalHits !== undefined && totalHits !== null && !Number.isNaN(Number(totalHits))) {
        return totalHits;
      }
      return '0'; // Return a string '0' instead of a number 0
    },
    totalProducts() {
      return this.$store.state.productState.productsCollection.length;
    },
    totalRenderedProducts() {
      return this.$store.state.productState.products.length;
    },
    totalPagesShown() {
      return Math.ceil(this.$store.state.productState.total_hits / this.$store.state.productState.productPerPage);
    },
    displayRange() {
      const { selectedPage } = this.$store.state.productState;
      const { productPerPage } = this.$store.state.productState;
      const totalHits = this.$store.state.productState.total_hits;

      if (totalHits !== undefined) {
        const startRange = (selectedPage - 1) * productPerPage + 1;
        const endRange = Math.min(selectedPage * productPerPage, totalHits);
        return `${startRange}-${endRange}`;
      }
      return '0-0'; // Or whatever fallback range you want to provide
    },
    totalPages() {
      const totalProducts = this.$store.state.productState.productsCollection.length;
      const productsPerPage = this.$store.state.productState.productPerPage;
      return Math.ceil(totalProducts / productsPerPage);
    },
    visiblePages() {
      const currentPage = this.$store.state.productState.selectedPage;
      const totalPages = this.$store.state.productState.pages;

      // Define the number of visible pages
      const visiblePageCount = 5;

      // Calculate the starting and ending page indexes
      let startPage = Math.max(currentPage - Math.floor(visiblePageCount / 2), 1);
      const endPage = Math.min(startPage + visiblePageCount - 1, totalPages);

      // Adjust the starting page if it goes beyond the boundary
      if (endPage - startPage + 1 < visiblePageCount) {
        startPage = Math.max(endPage - visiblePageCount + 1, 1);
      }

      // Generate an array of visible page numbers
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    },
    showNextArrow() {
      return this.$store.state.productState.selectedPage < this.$store.state.productState.pages;
    },
    showPrevArrow() {
      return this.$store.state.productState.selectedPage > 1;
    },

    isLoading() {
      return this.$store.state.productState.isLoading;
    },
    viewIconSrc() {
      return this.viewMode === 'grid' ? this.listIconSrc : this.gridIconSrc;
    },
    filteredProducts() {
      if (this.selectedOption[0] === 'All items') {
        // console.log('all items');
        this.$store.dispatch('productInitialList', {
          searchKeyword: this.$store.state.productState.searchTerm,
          selectOptionZero: this.$store.state.productState.collection_ids[0] !== null ? [this.$store.state.productState.collection_ids[0]] : [],
          selectOptionOne: this.$store.state.productState.group_ids[0] !== null ? [this.$store.state.productState.group_ids[0]] : [],
          selectOptionTwo: this.$store.state.productState.subgroup_ids[0] !== null ? [this.$store.state.productState.subgroup_ids[0]] : [],
          selectOptionThree: this.$store.state.productState.category_ids[0] !== null ? [this.$store.state.productState.category_ids[0]] : [],
          selectOptionFour: this.$store.state.productState.shell_colors[0] !== null ? [this.$store.state.productState.shell_colors[0]] : [],
          selectOptionFive: this.$store.state.productState.leg_colors[0] !== null ? [this.$store.state.productState.leg_colors[0]] : [],
          selectOptionSix: this.$store.state.productState.upholsteries[0] !== null ? [this.$store.state.productState.upholsteries[0]] : [],
          selectOptionSeven: this.$store.state.productState.cont_upholsteries[0] !== null ? [this.$store.state.productState.cont_upholsteries[0]] : [],
          selectOptionEight: this.$store.state.productState.config_groups[0] !== null ? [this.$store.state.productState.config_groups[0]] : [],
          selectedPage: 1,
          filter: '',
        });
        // this.$store.commit('pageChangeFeature', 1);
      } else if (this.selectedOption[0] === 'Published items') {
        // console.log('published');
        this.$store.dispatch('productInitialList', {
          searchKeyword: this.$store.state.productState.searchTerm,
          selectOptionZero: this.$store.state.productState.collection_ids[0] !== null ? [this.$store.state.productState.collection_ids[0]] : [],
          selectOptionOne: this.$store.state.productState.group_ids[0] !== null ? [this.$store.state.productState.group_ids[0]] : [],
          selectOptionTwo: this.$store.state.productState.subgroup_ids[0] !== null ? [this.$store.state.productState.subgroup_ids[0]] : [],
          selectOptionThree: this.$store.state.productState.category_ids[0] !== null ? [this.$store.state.productState.category_ids[0]] : [],
          selectOptionFour: this.$store.state.productState.shell_colors[0] !== null ? [this.$store.state.productState.shell_colors[0]] : [],
          selectOptionFive: this.$store.state.productState.leg_colors[0] !== null ? [this.$store.state.productState.leg_colors[0]] : [],
          selectOptionSix: this.$store.state.productState.upholsteries[0] !== null ? [this.$store.state.productState.upholsteries[0]] : [],
          selectOptionSeven: this.$store.state.productState.cont_upholsteries[0] !== null ? [this.$store.state.productState.cont_upholsteries[0]] : [],
          selectOptionEight: this.$store.state.productState.config_groups[0] !== null ? [this.$store.state.productState.config_groups[0]] : [],
          selectedPage: 1,
          filter: 'published',
        });
        // this.$store.commit('pageChangeFeature', 1);
      } else if (this.selectedOption[0] === 'Unpublished items') {
        // console.log('unpublished');
        this.$store.dispatch('productInitialList', {
          searchKeyword: this.$store.state.productState.searchTerm,
          selectOptionZero: this.$store.state.productState.collection_ids[0] !== null ? [this.$store.state.productState.collection_ids[0]] : [],
          selectOptionOne: this.$store.state.productState.group_ids[0] !== null ? [this.$store.state.productState.group_ids[0]] : [],
          selectOptionTwo: this.$store.state.productState.subgroup_ids[0] !== null ? [this.$store.state.productState.subgroup_ids[0]] : [],
          selectOptionThree: this.$store.state.productState.category_ids[0] !== null ? [this.$store.state.productState.category_ids[0]] : [],
          selectOptionFour: this.$store.state.productState.shell_colors[0] !== null ? [this.$store.state.productState.shell_colors[0]] : [],
          selectOptionFive: this.$store.state.productState.leg_colors[0] !== null ? [this.$store.state.productState.leg_colors[0]] : [],
          selectOptionSix: this.$store.state.productState.upholsteries[0] !== null ? [this.$store.state.productState.upholsteries[0]] : [],
          selectOptionSeven: this.$store.state.productState.cont_upholsteries[0] !== null ? [this.$store.state.productState.cont_upholsteries[0]] : [],
          selectOptionEight: this.$store.state.productState.config_groups[0] !== null ? [this.$store.state.productState.config_groups[0]] : [],
          selectedPage: 1,
          filter: 'sent',
        });
        // this.$store.commit('pageChangeFeature', 1);
      }
      return this.$store.state.productState.products;
    },
    searchClicked() {
      return this.$store.state.productState.searchClicked;
    },
  },

  data() {
    return {
      viewMode: 'grid',
      gridIconSrc: '../assets/grid-view.svg',
      listIconSrc: '../assets/list-view.svg',
      isActive: false,
      productState: productState.state(),
      showProduct: false,
      selectedProduct: null,
      fallbackImgUrl: '../assets/placeholder-image.svg',
      isScrolled: false,

      productItems: [
        {
          checked: false,
          imageSrc: '../assets/product1.png',
          imageAlt: 'Product 1',
          title: 'Era Lounge Chair High Black Steel',
          upholstery: '1320 - Ultra 41567',
          piping: '1320 - Ultra 41567',
          productNumber: '602860',
        },
        {
          checked: false,
          imageSrc: '../assets/product2.png',
          imageAlt: 'Product 2',
          title: 'Era Lounge Chair High Black Steel',
          upholstery: '1320 - Ultra 41567',
          piping: '1320 - Ultra 41567',
          productNumber: '602860',
        },
        {
          checked: false,
          imageSrc: '../assets/product3.png',
          imageAlt: 'Product 3',
          title: 'Era Lounge Chair High Black Steel',
          upholstery: '1320 - Ultra 41567',
          piping: '1320 - Ultra 41567',
          productNumber: '602860',
        },
        {
          checked: false,
          imageSrc: '../assets/product4.png',
          imageAlt: 'Product 3',
          title: 'Era Lounge Chair High Black Steel',
          upholstery: '1320 - Ultra 41567',
          piping: '1320 - Ultra 41567',
          productNumber: '602860',
        },
        {
          checked: false,
          imageSrc: '../assets/product4.png',
          imageAlt: 'Product 3',
          title: 'Era Lounge Chair High Black Steel',
          upholstery: '1320 - Ultra 41567',
          piping: '1320 - Ultra 41567',
          productNumber: '602860',
        },
      ],
    };
  },
  methods: {
    // toggleIconType() {
    //   this.isActive = !this.isActive;
    //   if (this.isActive) {
    //     this.gridlistIconSrc = '../assets/grid-view.svg';
    //   } else {
    //     this.gridlistIconSrc = '../assets/list-view.svg';
    //   }
    // },
    openProductPopup(product) {
      this.showProduct = true;
      this.selectedProduct = product;
      document.body.classList.add('o-hidden');
    },
    ProductClose() {
      this.showProduct = false;
      this.selectedProduct = null;
      document.body.classList.remove('o-hidden');
    },
    updateText() {
      const elements = this.$el.querySelectorAll('.product-col p');

      elements.forEach((paragraph) => {
        const spanElement = paragraph.querySelector('span:nth-child(2)');
        if (spanElement) {
          const originalText = spanElement.textContent;

          if (this.viewMode === 'list') {
            // Replace colon only in list-view
            const modifiedText = originalText.replace(/:/g, '');
            spanElement.textContent = modifiedText;
          } else if (this.viewMode === 'grid' && !originalText.includes(':')) {
            // Add back the colon in grid-view
            spanElement.textContent = `:${originalText}`;
          }
        }
      });
    },
    toggleView() {
      this.viewMode = this.viewMode === 'grid' ? 'list' : 'grid';
      this.updateText(this.viewMode);
    },
    toggleCheckbox(index) {
      // if (this.$store.state.productState.products[index].checked) {
      //   this.$store.commit('itemCountDecrement');
      // } else {
      //   this.$store.commit('itemCountIncrement');
      // }
      this.$store.state.productState.products[index].checked = !this.$store.state.productState.products[index].checked;
      // this.$store.commit('toggleSelectedItem', index);
      this.$store.dispatch('toggleSelectedItem', index);
    },

    getImageUrl(product) {
      if (product.image && !this.isInvalidImageUrl(product.image)) {
        return product.image;
      }
      return '../assets/placeholder-image.png';
    },
    isInvalidImageUrl(url) {
      // Add your logic to determine if the image URL is invalid
      // For example, you can check if the URL returns a valid image file
      // Return true if the URL is invalid, false otherwise
      // You can use regular expressions or other methods to validate the URL
      // Here's a simple example to check if the URL ends with .png:
      return !url.endsWith('.png');
    },
    handleImageError(event) {
      // Change the src attribute to the fallback image URL
      const imgElement = event.target;
      imgElement.src = this.fallbackImgUrl;
    },
    // changePage(page) {
    //   const nextPage = page;
    //   this.$store.state.productState.selectedPage = nextPage;
    //   const start = (nextPage - 1) * this.$store.state.productState.productPerPage;
    //   const end = start + this.$store.state.productState.productPerPage;
    //   this.$store.state.productState.products = this.$store.state.productState.productsCollection.slice(start, end);
    // },
    changePage(page) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      // console.log(page, this.productState.selectedPage);
      this.$store.commit('makeProductEmpty');
      this.productState.selectedPage = page;
      this.$store.commit('pageChangeFeature', page);
      this.$store.dispatch('productPaginationFeature', page);
      // const start = (page - 1) * this.productState.productPerPage;
      // const end = start + this.productState.productPerPage;
      // this.$store.state.productState.products = this.$store.state.productState.productsCollection.slice(
      //   start,
      //   end,
      // );
      //   Loader
      // this.$store.commit('setLoading', true);

      // // Hide the loader after 3 seconds (3000 milliseconds)
      // setTimeout(() => {
      //   // console.log('loader');
      //   this.$store.commit('setLoading', false);
      // }, 3000);
    },
    nextPage() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      const nextPage = this.$store.state.productState.selectedPage + 1;
      this.changePage(nextPage);
      //   Loader
      // this.$store.commit('setLoading', true);

      // // Hide the loader after 3 seconds (3000 milliseconds)
      // setTimeout(() => {
      //   console.log('loader');
      //   this.$store.commit('setLoading', false);
      // }, 3000);
    },
    prevPage() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      const prevPage = this.$store.state.productState.selectedPage - 1;
      this.changePage(prevPage);
      //   Loader
      // this.$store.commit('setLoading', true);

      // // Hide the loader after 3 seconds (3000 milliseconds)
      // setTimeout(() => {
      //   console.log('loader');
      //   this.$store.commit('setLoading', false);
      // }, 3000);
    },
    resetDropdownState() {
      this.isDropdownOpen[0] = false;
      this.selectedOption[0] = null;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  watch: {
    searchClicked(newValue) {
      if (newValue) {
        this.resetDropdownState();
        this.$store.commit('setSearchClicked', false);
      }
    },
    viewMode(newMode) {
      this.updateText(newMode);
    },
  },
  // created() {
  //   // Load cart items from localStorage on component creation
  //   const storedCartItems = localStorage.getItem('cartItems');
  //   console.log(storedCartItems);
  //   if (storedCartItems) {
  //     this.$store.dispatch('setSelectedItems', JSON.parse(storedCartItems));
  //   }
  // },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.label-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  position: absolute;
  left: 20px;
  top: -10px;
  background: #ffffff;
  padding: 0 0 0 8px;
}

.products {
  padding-bottom: 30px;

  .custom-select {
    margin-bottom: 0;

    .select-box {
      position: relative;
      cursor: pointer;
      padding: 6px 29px;
      border: 0.3px solid #A1A1A1;
    }

    .dropdown-menu {
      background: #EDEDED;
      border: 0.3px solid #D8D8D8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .top-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 32px;
    position: -webkit-sticky;
    position: sticky;
    top: 132px;
    z-index: 9;

    @media(max-width: 1024px) {
      position: static;
      top: auto;
      z-index: 9;
    }

    &:before {
      position: absolute;
      content: '';
      background-color: #fff;
      width: 100%;
      height: 80px;
      left: 0;
      bottom: -15px;
      transition: all .3s ease;
    }

    &.scrolled {
      &:before {
        box-shadow: 0 3px 18px -12px rgba(0, 0, 0, 0.15);
      }
    }

    .custom-select {
      margin-bottom: 0;
    }
  }

  .product-count {
    position: relative;

    @media(max-width: 576px) {
      margin-bottom: 15px;
      width: 100%;
      text-align: right;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      padding: 0;

      span {
        color: #909090;
        font-weight: 400;
      }
    }
  }
}

.sort {
  max-width: 206px;
  width: 100%;
  margin-left: 16px;
}

.grid-list-view {
  margin-left: 18px;
  position: relative;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 32px;
    background: #F7F7F7;
    border: 0.178125px solid #A1A1A1;
    border-radius: 0.59375px;
    line-height: 0;
    cursor: pointer;
  }
}

.product-listing {
  position: relative;
  min-height: calc(100vh - 196px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background: #fff;
}

.product-listing-wrap {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 24px -2.5px 0;
  padding-left: 27px;
  min-height: calc(100vh - 65px);
  width: -webkit-fill-available;
  width: -moz-available;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
}

.product-col {
  width: calc(100% / 4 - 5px);
  margin: 0 2.5px 6px;
  background: #FFFFFF;
  border: 0.4px solid #DDDDDD;
  position: relative;
  padding: 0 12px 20px;

  @media (max-width: 1280px) {
    width: calc(100% / 3 - 5px);
  }

  @media (max-width: 700px) {
    width: calc(100% / 2 - 5px);
  }

  @media (max-width: 475px) {
    width: calc(100% / 1 - 5px);
  }

  .title,
  .product-number {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #060606;
    margin: 0 0 3px;
  }

  .title {
    .ribbon {
      display: none;
    }
  }

  .content {
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #060606;
    margin: 0 0 3px;
    display: flex;

    span {
      display: inline-block;

      &:first-child {
        width: 45%;
      }

      &:last-child {
        width: 55%;
      }
    }
  }

  .checkbox {
    position: absolute;
    right: 9px;
    top: 8px;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #F2F3F5;
      box-shadow: 0px 2px 10px rgba(38, 51, 77, 0.03);
      padding: 10px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      // transition: all .3s ease;
      cursor: pointer;
    }

    &.checked {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 9px;
        width: 6px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        cursor: pointer;
      }

      &:before {
        background-color: #000;
      }
    }
  }

  &:hover {
    .checkbox {
      &:before {
        border-color: #A1A1A1;
      }

      &.checked {
        &:before {
          border-color: #F2F3F5;
        }
      }
    }
  }

  .ribbon {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    span {
      display: inline-block;
      padding: 1px 5px;
      border-radius: 0px 0px 8px 0px;
      margin-right: 5px;
    }

    .published {
      background: #03A14C;
    }

    .unpublished {
      background: #FF5858;
    }

    span {
      font-weight: 300;
      font-size: 10px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}

.empty-cart {
  margin: auto;
  text-align: center;

  .image {
    line-height: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 102%;
    color: #060606;
    font-family: 'gill_sans_mtregular';
    margin-bottom: 1px;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 169%;
    color: #060606;
    font-family: 'gill_sans_mtregular';
  }
}

.pagination {
  ul {
    padding: 15px 0 60px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    li {
      width: 30.91px;
      height: 30.91px;
      background: #EFF2F2;
      border-radius: 9.65909px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 11.5909px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 15px;

      &.active {
        background: #000000;
        color: #FFFFFF;
      }

      &.prev-page {
        transform: rotate(180deg);
      }

      @media (max-width: 480px) {
        margin-left: 10px;
      }
    }
  }
}

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  border: 3px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s loader linear infinite;
  position: relative;
}

.loader-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 99;
  width: 100%;
}

// .products {
//   .loader-wrap {
//     min-height: 100vh;
//   }
// }

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.list-heading {
  display: none;
}

.list-view {
  width: 100%;
  overflow: auto;

  .action {
    width: 70px;
    border-right: 0.4px solid #DDDDDD;
    padding: 0 10px;
  }

  .productName {
    width: 310px;
    border-right: 0.4px solid #DDDDDD;
    padding: 0 10px;
  }

  .sku {
    width: 145px;
    border-right: 0.4px solid #DDDDDD;
    padding: 0 10px;
  }

  .upholstery {
    width: 220px;
    border-right: 0.4px solid #DDDDDD;
    padding: 0 10px;
  }

  .pipingCushions {
    width: 224px;
    padding: 0 10px;
  }

  .product-col {
    width: 100%;
    padding: 0 0;
    display: flex;
    margin: 0 2.5px 0;
    border-top: 0;

    .image,
    .ribbon {
      display: none;
    }

    .checkbox {
      position: relative;
      top: auto;
      right: auto;
      width: 74px;
      border-right: 0.4px solid #DDDDDD;
      padding: 0 10px;

      &:before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        top: 48%;
        left: 42%;
        transform: rotate(45deg) translate(-50%, -50%);
      }
    }

    .content {
      width: 100%;
      display: flex;

      &>* {
        padding: 16px 10px;

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 4;
          border-right: 0;
        }
      }
    }

    .title {
      width: 310px;
      font-weight: 300;
      margin: 0;
      border-right: 0.4px solid #DDDDDD;

      .ribbon {
        display: block;
        background-color: transparent;
        font-size: 12px;
        line-height: 20px;
        position: static;
        margin: 0;
        padding: 0;

        &.published {
          color: #03A14C;
        }

        &.unpublished {
          color: #F55858;
        }
      }
    }

    .product-number {
      width: 145px;
      font-weight: 300;
      margin: 0;
      border-right: 0.4px solid #DDDDDD;
      display: flex;
      order: 2;
    }

    p {
      width: 220px;
      background: #fff;
      overflow: hidden;
      margin: 0;
      border-right: 0.4px solid #DDDDDD;

      &:last-child {
        border: 0;
      }
    }

    span {
      &:first-child {
        display: none;
      }

      &:last-child {
        width: 100%;
      }
    }
  }

  .product-listing-wrap {
    min-height: auto;
    margin-top: 0;
    width: 1037px;
  }

  .list-heading {
    display: flex;
    margin-left: 27px;
    border: 0.4px solid #DDDDDD;
    margin-top: 24px;
    width: 1005px;
    background-color: #FAFAFA;

    @media(max-width: 1024px) {
      margin-left: 0;
      width: 1030px;
    }

    p {
      margin: 0;
      padding: 16px 0;
      font-weight: 600;
      font-size: 13px;
      color: #060606;
      line-height: 20px;
    }
  }
}

.product-col .image {
  min-height: 222.25px;
  line-height: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;

  @media(max-width: 475px) {
    text-align: center;
  }
}

.product-listing .loader-wrap {
  align-items: center;
  // padding-top: calc(100vh - 60%);
  position: fixed;
}
</style>
