<template>
  <div class="login-wrap">
    <h1>{{ heading }}</h1>
    <p>Hey, Enter your details to get sign in to your account</p>
    <!-- Form starts here -->
    <form @submit.prevent="login" class="login-form">
      <div class="form-group" :class="{ error: emailError }">
        <label for="email">
          <input v-model="email" id="email" placeholder="Email address" autocomplete="off" />
          <!-- <span class="label-name">Email address</span> -->
        </label>
      </div>
      <div class="form-group" :class="{ error: passwordError }">
        <label for="password">
          <input
            :type="inputType"
            v-model="password"
            id="password"
            placeholder="Password"
            autocomplete="off"
          />
          <!-- <span class="label-name">Password</span> -->
        </label>
        <button class="eye-icon" @click.prevent="toggleInputType">
          <img :src="eyeIconSrc" alt="Eye icon" />
        </button>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div class="form-footer">
        <!-- <div class="col">
                    <p>Having trouble in <a href="#">sign in?</a></p>
                </div> -->
        <div class="col">
          <div class="btn-wrap">
            <button type="submit">
              Login
              <div class="loader-wrap" v-if="isLoading">
                <div class="loader"></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import emailValidator from "email-validator";
// const proxyurl = 'https://cors-anywhere.herokuapp.com/';
/* eslint-disable */

export default {
  name: "LoginPage",
  props: {
    heading: String,
  },
  data() {
    return {
      email: "",
      password: "",
      user_id: "",
      inputType: "password", // Initial input type
      eyeIconSrc: "../assets/eye.svg",
      errorMessage: "",
      emailError: false,
      passwordError: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.productState.isLoading;
    },
  },
  methods: {
    toggleInputType() {
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.eyeIconSrc =
        this.inputType === "password" ? "../assets/eye.svg" : "../assets/eye-off.svg";
    },
    login() {
      if (!this.validateForm()) {
        return;
      }
      const data = JSON.stringify({
        email: this.email,
        password: this.password,
      });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://configurator-api.normann-copenhagen.com/api/dash-board/login",
        headers: {
          "X-API-Key": "6548454121546548764564457464854546",
          "Content-Type": "application/json",
        },
        data,
      };

      this.$store.commit("setLoading", true);

      axios
        .request(config)
        .then((response) => {
          if (response && response.data.is_success) {
            Cookies.set("email", this.email);
            Cookies.set("user_id", response.data.user_id);
            Cookies.set("user_role", response.data.user_role);
            this.$router.push("/dashboard");
            // console.log(JSON.stringify(response.data));
          } else {
            this.errorMessage = "Login failed. Please check your credentials.";
            // console.log('failed');
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = "An error occurred during login. Please try again later.";
        })
        .finally(() => {
          // Reset isLoading after login request completes, regardless of success or failure
          this.$store.commit("setLoading", false);
        });

      const firstLetter = this.email.charAt(0).toUpperCase();
      this.$store.commit("setFirstLetter", firstLetter);
      // const email = this.email;
      this.$store.commit("setEmail", this.email);

      // Loader
      // this.$store.commit('setLoading', true);

      // Hide the loader after 3 seconds (3000 milliseconds)
      // setTimeout(() => {
      //   // console.log('loader');
      //   this.$store.commit('setLoading', false);
      // }, 3000);
    },

    validateForm() {
      let isValidEmail = false;

      let isValidPassword = false;

      if (this.email) {
        const isEmailValid = emailValidator.validate(this.email);

        if (isEmailValid) {
          isValidEmail = true;
        }
      }

      this.emailError = !isValidEmail;

      if (this.password) {
        isValidPassword = true;
      }

      this.passwordError = !isValidPassword;

      if (isValidEmail && isValidPassword) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  &-wrap {
    text-align: center;
    padding-top: 120px;

    @media (max-width: 1199px) {
      padding-top: 50px;
    }

    h1 {
      font-family: "gill_sans_mtbold";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #000000;
      margin-bottom: 5px;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      color: #555555;
    }
  }

  &-form {
    max-width: 551px;
    margin: 85px auto 0;

    @media (max-width: 991px) {
      margin-top: 50px;
    }

    .form-group {
      position: relative;
      margin-bottom: 13px;

      .eye-icon {
        position: absolute;
        right: 26px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: 0;
        outline: 0;
      }
    }

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #525252;
      position: relative;
      height: 76px;
      display: block;

      span {
        display: block;
      }

      .label-name {
        position: absolute;
        left: 23px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;
      }
    }

    input {
      background: #fcfcfc;
      border: 0.3px solid #e7e7e7;
      max-width: 551px;
      width: 100%;
      height: 76px;
      padding: 15px 30px 15px 23px;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #525252;

      &:focus + .label-name,
      &:not(:placeholder-shown) + .label-name {
        display: none;
        background-color: transparent !important;
      }

      &::placeholder {
        color: #525252;
      }
    }

    .form-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: left;
      margin-top: 34px;

      @media (max-width: 1199px) {
        text-align: center;
      }

      .col {
        width: 100%;

        @media (max-width: 1199px) {
          width: 100%;
        }
      }

      p {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #a5a5a5;
        margin: 0;

        a {
          color: #000000;
        }
      }

      .btn-wrap {
        text-align: right;

        @media (max-width: 1199px) {
          text-align: center;
          margin-top: 20px;
        }

        button {
          cursor: pointer;
        }
      }
    }

    .error-message {
      color: red;
      margin: 15px 0 30px;
    }
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fcfcfc inset;
  // -webkit-text-fill-color: transparent;
}

.login-form .form-group.error input {
  border-color: red;
}

.login-form .form-footer .btn-wrap button .loader-wrap {
  width: auto;
}

.login-form .form-footer .btn-wrap button {
  position: relative;
}

.login-form label[for="password"] input {
  padding-right: 65px;
}

// input:-webkit-autofill:focus {
//     -webkit-text-fill-color: #000;
// }
</style>
