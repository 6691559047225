<template>
  <div>
    <!-- <div v-if="$store.state.productState.selectedItems.length > 0" id="cartview"
      :class="{ 'cartview': true, 'cart-open': visible }"> -->
    <div id="cartview" :class="{ cartview: true, 'cart-open': visible }">
      <div class="cartview-content">
        <!-- cart close -->
        <div class="cartview-close">
          <span @click="cartClose" @keydown="cartClose">
            close
            <img src="../assets/cartview-close.svg" alt="close-icon" />
          </span>
        </div>
        <!-- cart heading -->
        <div class="cartview-heading">
          <h5>Selected items</h5>
          <p>{{ $store.state.productState.selectedItems.length }} items selected</p>
        </div>
        <!-- cart list view  -->
        <div
          class="cartview-list-wrapper"
          v-if="$store.state.productState.selectedItems.length !== 0"
        >
          <div
            class="cartview-list--item"
            v-for="(selectedItem, index) in $store.state.productState.selectedItems"
            :key="index"
          >
            <div class="cartview-list--item__image">
              <!-- <img :src="getImageUrl(selectedItem)" :alt="selectedItem.imageAlt" /> -->
              <img
                :src="selectedItem.image"
                @error="handleImageError"
                :alt="selectedItem.imageAlt"
              />
            </div>
            <div class="cartview-list--item__detail">
              <div class="view-item-content">
                <h3>{{ selectedItem.title }}</h3>
                <p>
                  Upholstery : {{ product && selectedItem.upholstery_nav_id }} -
                  {{ selectedItem && selectedItem.upholstery_supplier_id }}
                </p>
                <p>
                  <span>Piping & Cushions: </span>
                  <span
                    >: {{ selectedItem.piping_and_cushion_nav_id }} -
                    {{ selectedItem.piping_and_cushion_supplier_id }}</span
                  >
                </p>
                <p>Legs: {{ selectedItem && selectedItem.leg_nav_id }}</p>
                <h4>{{ selectedItem.art_no }}</h4>
              </div>
            </div>
            <div class="cartview-list--item__remove">
              <div
                class="remove-block"
                @click="deleteCartItem(selectedItem)"
                @keydown.enter="deleteCartItem(selectedItem)"
                tabindex="0"
              >
                <img src="../assets/delete.svg" alt="close icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-view-button" v-if="isStandardUser">
        <button @click="openJsonPopup" @keydown.enter="openJsonPopup" tabindex="0">
          Download to vocast
        </button>
      </div>
      <div class="cart-view-button" v-else>
        <button @click="openJsonPopup" @keydown.enter="openJsonPopup" tabindex="0">
          Download to vocast
        </button>
        <button @click="openProgressPopup" @keydown.enter="openProgressPopup" tabindex="0">
          Send to Sitecore
        </button>
        <button @click="sendToDataSheet" @keydown.enter="sendToDataSheet" tabindex="0">
          Send to Data Sheet
        </button>
      </div>
    </div>

    <!-- Success Popup -->
    <div v-if="showSuccessPopup" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success!</h5>
            <button type="button" class="close" @click="closeSuccessPopup">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Data sent successfully!</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeSuccessPopup">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Popup -->
    <div v-if="showErrorPopup" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Error!</h5>
            <button type="button" class="close" @click="closeErrorPopup">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>An error occurred: {{ errorMessage }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeErrorPopup">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <progressPopup :visible="showProgressPopup" @close="closeProgressPopup" /> -->
    <progressPopup
      :visible="$store.state.productState.showProgressPopup"
      @close="closeProgressPopup"
    />
    <progressPopupDatasheet
      :visible="$store.state.productState.showProgressPopupDataSheet"
      @close="closeProgressPopupDataSheet"
    />
  </div>
</template>
<script>
// import { store } from 'vuex';
// eslint-disable-next-line
// import * as XLSX from 'xlsx';
/* eslint-disable */
import axios from "axios";
import progressPopup from "./progressPopup.vue";
import progressPopupDatasheet from "./ProgressPopupDataSheet.vue";
import Cookies from "js-cookie";

export default {
  name: "cartView",
  // store,
  props: {
    visible: Boolean,
    onCartItemsDeleted: {
      type: Function,
      required: true,
    },
  },
  components: {
    progressPopup,
    progressPopupDatasheet,
  },
  data() {
    return {
      showPopup: true,
      showProgressPopup: false,
      showProgressPopupDataSheet: false,
      fallbackImgUrl: "../assets/placeholder-image.svg",
      showSuccessPopup: false,
      showErrorPopup: false,
      errorMessage: "",
      userRole: this.$store.state.productState.authRole,
    };
  },
  methods: {
    cartClose() {
      this.$emit("close");
    },
    deleteCartItem(selectedItem) {
      const { products } = this.$store.state.productState;
      const { searchedProducts } = this.$store.state.productState;

      const indexInProducts = products.findIndex(
        (obj) =>
          obj.item_id === selectedItem.item_id &&
          obj.leg_item_id === selectedItem.leg_item_id &&
          obj.upholstery_item_id === selectedItem.upholstery_item_id &&
          obj.shell_item_id === selectedItem.shell_item_id &&
          obj.piping_and_cushion_item_id === selectedItem.piping_and_cushion_item_id &&
          obj.guid === selectedItem.guid
      );

      if (indexInProducts !== -1) {
        products[indexInProducts].checked = false;
      } else {
        const indexInSearchedProducts = searchedProducts.findIndex(
          (obj) =>
            obj.item_id === selectedItem.item_id &&
            obj.leg_item_id === selectedItem.leg_item_id &&
            obj.upholstery_item_id === selectedItem.upholstery_item_id &&
            obj.shell_item_id === selectedItem.shell_item_id &&
            obj.piping_and_cushion_item_id === selectedItem.piping_and_cushion_item_id &&
            obj.guid === selectedItem.guid
        );

        if (indexInSearchedProducts !== -1) {
          searchedProducts[indexInSearchedProducts].checked = false;
        }
      }

      this.$store.commit("selectedProductToSelectedArray", selectedItem);
      localStorage.setItem(
        "cartItems",
        JSON.stringify(this.$store.state.productState.selectedItems)
      );

      if (this.$store.state.productState.selectedItems.length === 0) {
        this.onCartItemsDeleted();
      }
    },

    openJsonPopup() {
      const selectedItems = this.$store.state.productState.selectedItems;
      const cartIds = selectedItems.map((item) => item.cart_id);
      const email = Cookies.get("email");
      const userId = Cookies.get("user_id");

      const dataToSend = {
        cartIds: cartIds,
        email: email,
        userId: userId,
      };

      axios
        .post(
          "https://configurator-api.normann-copenhagen.com/api/dash-board/export-cylindo-images",
          dataToSend,
          {
            headers: {
              "X-API-Key": "6548454121546548764564457464854546",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showSuccessPopup = true;
          // console.log('Data sent successfully:', response.data);
          // const itemsToDelete = [...selectedItems];
          // itemsToDelete.forEach(item => this.deleteCartItem(item));
          // this.$store.dispatch('toggleSelectedItem', index);

          this.$store.state.productState.selectedItems = [];
          this.$store.state.productState.selectedItemCount = 0;
          // localStorage.setItem('cartItems', JSON.stringify([]));
          // const userId = Cookies.get('user_id');

          // const existingCartData = JSON.parse(localStorage.getItem('cartItems')) || {};

          // existingCartData[userId] = this.$store.state.productState.selectedItems;

          // localStorage.setItem('cartItems', JSON.stringify(existingCartData));

          localStorage.setItem(
            "cartItems",
            JSON.stringify(this.$store.state.productState.selectedItems)
          );

          this.$emit("close");
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.showErrorPopup = true;
          // console.error('Error sending data:', error);
        });
    },

    closeSuccessPopup() {
      this.showSuccessPopup = false;
    },
    closeErrorPopup() {
      this.showErrorPopup = false;
    },

    openProgressPopup() {
      // this.showProgressPopup = true;
      // this.$store.state.productState.selectedItems[index].status = 1;
      this.$store.commit("resetPauseDataTransfer");
      this.$store.dispatch("sendToSitecore");
      this.$store.commit("showProgressPopup");
      document.body.classList.add("o-hidden");
      // this.$store.state.productState.selectedItems.forEach((element) => {
      //   this.deleteCartItem(element);
      // });
    },
    sendToDataSheet() {
      this.$store.commit("resetPauseDataTransfer");
      this.$store.dispatch("sendToDataSheet");
      this.$store.commit("showProgressPopupDataSheet");
      document.body.classList.add("o-hidden");
    },
    closeProgressPopup() {
      this.showProgressPopup = false;
      document.body.classList.remove("o-hidden");
      this.$store.state.productState.showProgressPopup = false;
    },
    closeProgressPopupDataSheet() {
      this.showProgressPopupDataSheet = false;
      document.body.classList.remove("o-hidden");
      this.$store.state.productState.showProgressPopupDataSheet = false;
    },
    getImageUrl(product) {
      if (product.image && !this.isInvalidImageUrl(product.image)) {
        return product.image;
      }
      return "../assets/placeholder-image.png";
    },
    isInvalidImageUrl(url) {
      return !url.endsWith(".png");
    },
    handleImageError(event) {
      // Change the src attribute to the fallback image URL
      const imgElement = event.target;
      imgElement.src = this.fallbackImgUrl;
    },
  },
  computed: {
    popupClasses() {
      return {
        popup: true,
        show: this.visible,
      };
    },
    isStandardUser() {
      // console.log(
      //   "this.$store.state.productState.authRole",
      //   this.$store.state.productState.authRole
      // );
      // console.log("this.userRole", this.userRole);
      return this.userRole === "2";
    },
  },
};
</script>
<style scoped lang="scss">
.cartview {
  display: none;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  right: 0;
  height: calc(100vh - 100px);
  background: #fff;
  width: 100%;
  max-width: 491px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 250px #000000;
  padding: 0px;
  justify-content: space-between;
  align-content: space-between;
  z-index: 999;
  -webkit-backface-visibility: hidden;

  &.cart-open {
    display: flex;
    animation: slide-in 0.5s ease-in-out;

    &:after {
      position: fixed;
      content: "";
      width: 100vw;
      height: 100vh;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    align-content: flex-start;
    padding: 20px 5px 0px 17px;
    z-index: 9;
  }

  &-close {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    justify-content: flex-end;
    padding-right: 17px;

    span {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      font-family: "gill_sans_mtregular";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      cursor: pointer;
      color: #000000;

      img {
        line-height: 0;
        display: block;
        padding-left: 8px;
      }
    }
  }

  &-heading {
    display: block;
    width: 100%;
    max-width: 100%;
    position: relative;
    padding-right: 17px;

    h5 {
      font-family: "gill_sans_mtregular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 1px;
    }

    p {
      font-family: "gill_sans_mtregular";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 12px;
    }
  }

  // cart view item styling
  &-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 305px);
    overflow-y: auto;
    padding-right: 17px;

    &:-webkit-scrollbar {
      width: 10px;
      border-radius: 50px;
    }
  }

  /* Track */
  .cartview-list-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 38px;
    transform: translateX(-50px);
    border-radius: 50px;
  }

  /* Track */
  .cartview-list-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
  }

  /* Handle */
  .cartview-list-wrapper::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 50px;
  }

  /* Handle on hover */
  .cartview-list-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
  }

  .cartview-list--item {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    border: 0.5px solid rgba(196, 196, 196, 0.5);
    box-shadow: 0px 10px 20px rgba(151, 151, 151, 0.1);
    width: 100%;
    min-height: 100px;
    padding: 10px 4px 10px 9px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0px;
    }

    &__image {
      width: 87px;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      @media (max-width: 475px) {
        width: 60px;
      }
    }

    &__detail {
      width: calc(100% - 143px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-left: 10px;

      @media (max-width: 475px) {
        width: calc(100% - 100px);
        padding-right: 15px;
      }

      .view-item-content {
        display: block;
        width: 100%;
        max-width: 100%;
        position: relative;

        h3 {
          margin-bottom: 5px;
          font-family: "gill_sans_mtregular";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0px;
          /* identical to box height, or 143% */

          color: #060606;
        }

        p {
          margin-bottom: 5px;
          font-family: "gill_sans_mtregular";
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */

          color: #060606;
        }

        h4 {
          margin-bottom: 0px;
          font-family: "gill_sans_mtregular";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */

          color: #060606;
        }
      }
    }

    &__remove {
      width: 56px;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      @media (max-width: 475px) {
        width: 40px;
      }

      .remove-block {
        width: 36px;
        height: 36px;
        background: #f8eded;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  .cart-view-button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    align-content: flex-end;
    z-index: 9;

    button {
      width: 100%;
      max-width: 100%;
      position: relative;
      padding: 19.5px 10px;
      text-align: center;
      background: #000;
      font-family: "gill_sans_mtregular";
      font-style: normal;
      font-size: 18px;
      line-height: 20px;
      color: #fff;
      cursor: pointer;
      border: 0;
      width: 50%;
      &:first-child {
        background: #e9e9e9;
        width: 100%;
        color: #b7b7b7;
      }

      &:nth-child(2) {
        background: #818287;
      }
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.37);
  display: flex;
  align-items: center;
  justify-content: center;

  &-dialog {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 30px;
  }

  &-title {
    font-size: 28px;
    color: #000;
    margin-bottom: 25px;
  }

  &-header {
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      border: 0;
      font-size: 28px;
      padding: 0;
      line-height: 0;
      cursor: pointer;
    }
  }

  &-body {
    p {
      font-size: 17px;
      margin-bottom: 27px;
    }
  }

  &-footer {
    button {
      background-color: #000;
      color: #fff;
      border: 0;
      padding: 8px 16px;
      cursor: pointer;
    }
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateY(0%);
  }
}
</style>
