import axios from "axios";
import Cookies from "js-cookie";
// import { createStore } from 'vuex';

// const proxyurl = 'https://cors-anywhere.herokuapp.com/';
const productState = {
  state: () => ({
    productsCollection: [],
    productsFilteredCollection: [],
    products: [],
    searchedProducts: [],
    cartProducts: [],
    pages: 0,
    productPerPage: 64,
    selectedPage: 1,
    searchTerm: "",
    upholsteries: null,
    leg_colors: null,
    cont_upholsteries: null,
    shell_colors: null,
    collection_ids: null,
    group_ids: null,
    subgroup_ids: null,
    category_ids: null,
    config_groups: null,
    filter: "",
    selectedItemCount: 0,
    selectedItems: [],
    total_hits: null,
    remaining_hits: null,
    isLoading: false,
    user_id: null,
    item_id: null,
    leg_item_id: null,
    upholstery_item_id: null,
    shell_item_id: null,
    piping_and_cushion_item_id: null,
    guid: null,
    b2cguid: null,
    b2bguid: null,
    cartItems: [],
    user_id_cart: null,
    item_id_cart: null,
    leg_item_id_cart: null,
    upholstery_item_id_cart: null,
    shell_item_id_cart: null,
    piping_and_cushion_item_id_cart: null,
    guid_cart: null,
    cart_id: null,
    completedItems: 0,
    failedItems: 0,
    showSendItemsList: false,
    showSendItemsListDataSheet: false,
    sendItemsList: [],
    showProgressPopup: false,
    showProgressPopupDataSheet: false,
    showCancelPopup: false,
    firstLetter: "",
    pauseDataTransfer: false,
    selected: "",
    options: [["All items", "Published items", "Unpublished items"]],
    searchClicked: false,
    email: "",
    authToken: "",
    authRole: "",
  }),

  actions: {
    async productInitialList(
      { commit, state },
      {
        searchKeyword,
        selectOptionZero,
        selectOptionOne,
        selectOptionTwo,
        selectOptionThree,
        selectOptionFour,
        selectOptionFive,
        selectOptionSix,
        selectOptionSeven,
        selectOptionEight,
        selectedPage,
        filter,
      }
    ) {
      try {
        // Set loading state to true before making the API call
        commit("setLoading", true);

        const data = JSON.stringify({
          art_no: searchKeyword,
          collection_ids:
            selectOptionFour !== null && selectOptionFour.length !== 0 && selectOptionFour[0] !== ""
              ? selectOptionFour
              : [],
          group_ids:
            selectOptionFive !== null && selectOptionFive.length !== 0 && selectOptionFive[0] !== ""
              ? selectOptionFive
              : [],
          subgroup_ids:
            selectOptionSix !== null && selectOptionSix.length !== 0 && selectOptionSix[0] !== ""
              ? selectOptionSix
              : [],
          category_ids:
            selectOptionSeven !== null &&
            selectOptionSeven.length !== 0 &&
            selectOptionSeven[0] !== ""
              ? selectOptionSeven
              : [],
          config_groups:
            selectOptionEight !== null &&
            selectOptionEight.length !== 0 &&
            selectOptionEight[0] !== ""
              ? selectOptionEight
              : [],
          shell_colors:
            selectOptionThree !== null &&
            selectOptionThree.length !== 0 &&
            selectOptionThree[0] !== ""
              ? selectOptionThree
              : [],
          leg_colors:
            selectOptionOne !== null && selectOptionOne.length !== 0 && selectOptionOne[0] !== ""
              ? selectOptionOne
              : [],
          upholsteries:
            selectOptionZero !== null && selectOptionZero.length !== 0 && selectOptionZero[0] !== ""
              ? selectOptionZero
              : [],
          cont_upholsteries:
            selectOptionTwo !== null && selectOptionTwo.length !== 0 && selectOptionTwo[0] !== ""
              ? selectOptionTwo
              : [],
          items_per_page: state.productPerPage,
          page: selectedPage,
          filter,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://configurator-api.normann-copenhagen.com/api/dash-board/get/",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-API-Key": "6548454121546548764564457464854546",
          },
          data,
        };

        // console.log('Data set:', selectOptionZero, selectOptionOne);
        const response = await axios.request(config);

        commit("initialProducts", {
          totalData: response.data,
          art_no: searchKeyword,
          collection_ids: selectOptionFour,
          group_ids: selectOptionFive,
          subgroup_ids: selectOptionSix,
          category_ids: selectOptionSeven,
          config_groups: selectOptionEight,
          shell_colors: selectOptionThree,
          leg_colors: selectOptionOne,
          upholsteries: selectOptionZero,
          cont_upholsteries: selectOptionTwo,
        });
        this.totalHits = response.data.total_hits;
        this.remainingHits = response.data.remaining_hits;

        // Set loading state to false after content is loaded
        commit("setLoading", false);
      } catch (error) {
        console.log(error);
        // Set loading state to false in case of an error
        commit("setLoading", false);
      }
    },
    async productPaginationFeature({ commit, state }, payload) {
      try {
        // Set loading state to true before making the API call
        commit("setLoading", true);

        const data = JSON.stringify({
          art_no: state.searchTerm,
          collection_ids:
            state.collection_ids !== null &&
            state.collection_ids.length !== 0 &&
            state.collection_ids[0] !== ""
              ? state.collection_ids
              : [],
          group_ids:
            state.group_ids !== null && state.group_ids.length !== 0 && state.group_ids[0] !== ""
              ? state.group_ids
              : [],
          subgroup_ids:
            state.subgroup_ids !== null &&
            state.subgroup_ids.length !== 0 &&
            state.subgroup_ids[0] !== ""
              ? state.subgroup_ids
              : [],
          category_ids:
            state.category_ids !== null &&
            state.category_ids.length !== 0 &&
            state.category_ids[0] !== ""
              ? state.category_ids
              : [],
          config_groups:
            state.config_groups !== null &&
            state.config_groups.length !== 0 &&
            state.config_groups[0] !== ""
              ? state.config_groups
              : [],
          shell_colors:
            state.shell_colors !== null &&
            state.shell_colors.length !== 0 &&
            state.shell_colors[0] !== ""
              ? state.shell_colors
              : [],
          leg_colors:
            state.leg_colors !== null && state.leg_colors.length !== 0 && state.leg_colors[0] !== ""
              ? state.leg_colors
              : [],
          upholsteries:
            state.upholsteries !== null &&
            state.upholsteries.length !== 0 &&
            state.upholsteries[0] !== ""
              ? state.upholsteries
              : [],
          cont_upholsteries:
            state.cont_upholsteries !== null &&
            state.cont_upholsteries.length !== 0 &&
            state.cont_upholsteries[0] !== ""
              ? state.cont_upholsteries
              : [],
          items_per_page: state.productPerPage,
          filter: state.filter,
          page: payload,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://configurator-api.normann-copenhagen.com/api/dash-board/get/",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-API-Key": "6548454121546548764564457464854546",
          },
          data,
        };

        const response = await axios.request(config);

        commit("initialProducts", {
          totalData: response.data,
          art_no: state.searchTerm,
          collection_ids: state.collection_ids,
          group_ids: state.group_ids,
          subgroup_ids: state.subgroup_ids,
          category_ids: state.category_ids,
          config_groups: state.config_groups,
          shell_colors: state.shell_colors,
          leg_colors: state.leg_colors,
          upholsteries: state.upholsteries,
          cont_upholsteries: state.cont_upholsteries,
          items_per_page: state.productPerPage,
          page: state.selectedPage,
        });

        // Set loading state to false after content is loaded
        commit("setLoading", false);
      } catch (error) {
        console.log(error);
        // Set loading state to false in case of an error
        commit("setLoading", false);
      }
    },
    toggleSelectedItem({ state, commit }, payload) {
      let selectedItem = null;
      selectedItem = state.products[payload];
      if (selectedItem == null) {
        selectedItem = state.searchedProducts[payload];
      }
      if (selectedItem) {
        commit("selectedProductToSelectedArray", selectedItem);
      }
      const data = JSON.stringify({
        user_id: Cookies.get("user_id"),
        item_id: selectedItem.item_id,
        leg_item_id: selectedItem.leg_item_id,
        upholstery_item_id: selectedItem.upholstery_item_id,
        shell_item_id: selectedItem.shell_item_id,
        piping_and_cushion_item_id: selectedItem.piping_and_cushion_item_id,
        guid: selectedItem.guid,
        // b2cguid: selectedItem.b2cguid,
        b2b_guid: selectedItem.b2b_guid,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://configurator-api.normann-copenhagen.com/api/dash-board/addtocart",
        headers: {
          "X-API-Key": "6548454121546548764564457464854546",
          "Content-Type": "application/json",
        },
        data,
      };

      axios
        .request(config)
        .then((response) => {
          state.products[payload].cart_id = response.data.cart_id;
          state.products[payload].status = 1;
        })
        .catch((error) => {
          console.log(error);
        });

      // localStorage.setItem('cartItems', JSON.stringify(state.selectedItems));
      // const cartData = {
      //   userId: Cookies.get('user_id'),
      //   items: state.selectedItems,
      // };
      // localStorage.setItem('cartItems', JSON.stringify(cartData));
      const userId = Cookies.get("user_id");

      // Retrieve the existing cart data from localStorage (if any)
      const existingCartData = JSON.parse(localStorage.getItem("cartItems")) || {};

      // Update cart data with new user's selected items
      existingCartData[userId] = state.selectedItems;

      // Store the updated data back in localStorage
      localStorage.setItem("cartItems", JSON.stringify(existingCartData));
    },

    async sendToSitecore({ state, commit }) {
      const selectedItems = [...state.selectedItems]; // Create a copy of the selectedItems array
      let product = null;
      // Send all items at the same time
      selectedItems.forEach((item) => {
        product = state.products.find(
          (x) =>
            x.item_id === item.item_id &&
            // && x.leg_item_id === item.leg_item_id
            // && x.upholstery_item_id === item.upholstery_item_id
            // && x.shell_item_id === item.shell_item_id
            // && x.piping_and_cushion_item_id === item.piping_and_cushion_item_id
            // && x.guid === item.guid
            x.piping_and_cushion_nav_id === item.piping_and_cushion_nav_id &&
            x.piping_and_cushion_supplier_id === item.piping_and_cushion_supplier_id &&
            x.art_no === item.art_no &&
            // && x.config_group_title === item.config_group_title
            // && x.ean === item.ean
            x.group_id === item.group_id &&
            x.image === item.image &&
            // && x.is_published === item.is_published
            // && x.is_sent === item.is_sent
            x.leg_nav_id === item.leg_nav_id &&
            x.leg_supplier_id === item.leg_supplier_id &&
            x.shell_nav_id === item.shell_nav_id &&
            x.shell_supplier_id === item.shell_supplier_id &&
            x.title === item.title &&
            x.upholstery_nav_id === item.upholstery_nav_id &&
            x.upholstery_supplier_id === item.upholstery_supplier_id
        );
        if (product == null) {
          product = state.searchedProducts.find(
            (x) =>
              x.item_id === item.item_id &&
              // && x.leg_item_id === item.leg_item_id
              // && x.upholstery_item_id === item.upholstery_item_id
              // && x.shell_item_id === item.shell_item_id
              // && x.piping_and_cushion_item_id === item.piping_and_cushion_item_id
              // && x.guid === item.guid
              x.piping_and_cushion_nav_id === item.piping_and_cushion_nav_id &&
              x.piping_and_cushion_supplier_id === item.piping_and_cushion_supplier_id &&
              x.art_no === item.art_no &&
              // && x.config_group_title === item.config_group_title
              // && x.ean === item.ean
              x.group_id === item.group_id &&
              x.image === item.image &&
              // && x.is_published === item.is_published
              // && x.is_sent === item.is_sent
              x.leg_nav_id === item.leg_nav_id &&
              x.leg_supplier_id === item.leg_supplier_id &&
              x.shell_nav_id === item.shell_nav_id &&
              x.shell_supplier_id === item.shell_supplier_id &&
              x.title === item.title &&
              x.upholstery_nav_id === item.upholstery_nav_id &&
              x.upholstery_supplier_id === item.upholstery_supplier_id
          );
        }

        // console.log(product);
        if (product) {
          commit("addProductToSendItemsList", product);
          state.completedItems = 0;
          state.failedItems = 0;
        }
      });

      const configArray = selectedItems.map((item) => ({
        method: "post",
        maxBodyLength: Infinity,
        url: `https://configurator-api.normann-copenhagen.com/api/dash-board/send/?cart_id=${item.cart_id}`,
        headers: {
          "X-API-Key": "6548454121546548764564457464854546",
          "Content-Type": "application/json",
        },
      }));
      // console.log(configArray);
      try {
        let i = 0;
        while (i < configArray.length) {
          if (state.pauseDataTransfer) {
            // Pause data transfer if needed
            // eslint-disable-next-line
            await new Promise((resolve) => {
              let intervalId;

              const checkPauseStatus = () => {
                if (!state.pauseDataTransfer) {
                  clearInterval(intervalId);
                  resolve();
                }
              };

              intervalId = setInterval(checkPauseStatus, 1); // Check every 10 milliseconds
            });
          } else {
            commit("updateItemStatus", { index: i, statusData: 2 });

            try {
              // eslint-disable-next-line
              const response = await axios.request(configArray[i]);
              const { b2cguid } = response.data;
              const { b2bguid } = response.data;
              const { message } = response.data;
              const { status } = response.data;
              // console.log('guid', b2cguid, b2bguid);
              if (status === "Success") {
                commit("updateItemStatus", {
                  index: i,
                  statusData: 3,
                  b2cguid,
                  b2bguid,
                  message,
                });
                commit("updateGuid", { index: i, b2cguid, b2bguid });
                commit("completedItemsCount");
                commit("completedItemsDecrementCount");
              } else {
                commit("updateItemStatus", {
                  index: i,
                  statusData: 4,
                  b2cguid: null,
                  b2bguid: null,
                  message,
                });
                commit("failedItemsCount");
                commit("completedItemsDecrementCount");
              }
            } catch (e) {
              commit("updateItemStatus", {
                index: i,
                statusData: 4,
                b2cguid: null,
                b2bguid: null,
                message: "",
              });
              commit("failedItemsCount");
              commit("completedItemsDecrementCount");
            }

            i += 1;
          }
        }
      } catch (error) {
        console.log(error);
        selectedItems.forEach((item, index) => {
          commit("updateItemStatus", { index, statusData: 4 });
        });
      }
      return null;
    },

    async sendToDataSheet({ state, commit }) {
      const selectedItems = [...state.selectedItems]; // Create a copy of the selectedItems array
      let product = null;
      // Send all items at the same time
      selectedItems.forEach((item) => {
        product = state.products.find(
          (x) =>
            x.item_id === item.item_id &&
            // && x.leg_item_id === item.leg_item_id
            // && x.upholstery_item_id === item.upholstery_item_id
            // && x.shell_item_id === item.shell_item_id
            // && x.piping_and_cushion_item_id === item.piping_and_cushion_item_id
            // && x.guid === item.guid
            x.piping_and_cushion_nav_id === item.piping_and_cushion_nav_id &&
            x.piping_and_cushion_supplier_id === item.piping_and_cushion_supplier_id &&
            x.art_no === item.art_no &&
            // && x.config_group_title === item.config_group_title
            // && x.ean === item.ean
            x.group_id === item.group_id &&
            x.image === item.image &&
            // && x.is_published === item.is_published
            // && x.is_sent === item.is_sent
            x.leg_nav_id === item.leg_nav_id &&
            x.leg_supplier_id === item.leg_supplier_id &&
            x.shell_nav_id === item.shell_nav_id &&
            x.shell_supplier_id === item.shell_supplier_id &&
            x.title === item.title &&
            x.upholstery_nav_id === item.upholstery_nav_id &&
            x.upholstery_supplier_id === item.upholstery_supplier_id
        );
        if (product == null) {
          product = state.searchedProducts.find(
            (x) =>
              x.item_id === item.item_id &&
              // && x.leg_item_id === item.leg_item_id
              // && x.upholstery_item_id === item.upholstery_item_id
              // && x.shell_item_id === item.shell_item_id
              // && x.piping_and_cushion_item_id === item.piping_and_cushion_item_id
              // && x.guid === item.guid
              x.piping_and_cushion_nav_id === item.piping_and_cushion_nav_id &&
              x.piping_and_cushion_supplier_id === item.piping_and_cushion_supplier_id &&
              x.art_no === item.art_no &&
              // && x.config_group_title === item.config_group_title
              // && x.ean === item.ean
              x.group_id === item.group_id &&
              x.image === item.image &&
              // && x.is_published === item.is_published
              // && x.is_sent === item.is_sent
              x.leg_nav_id === item.leg_nav_id &&
              x.leg_supplier_id === item.leg_supplier_id &&
              x.shell_nav_id === item.shell_nav_id &&
              x.shell_supplier_id === item.shell_supplier_id &&
              x.title === item.title &&
              x.upholstery_nav_id === item.upholstery_nav_id &&
              x.upholstery_supplier_id === item.upholstery_supplier_id
          );
        }

        // console.log(product);
        if (product) {
          commit("addProductToSendItemsList", product);
          state.completedItems = 0;
          state.failedItems = 0;
        }
      });

      const configArray = selectedItems.map((item) => ({
        method: "post",
        maxBodyLength: Infinity,
        url: `https://configurator-api.normann-copenhagen.com/api/dash-board/send-to-data-sheet/?cart_id=${item.cart_id}`,
        headers: {
          "X-API-Key": "6548454121546548764564457464854546",
          "Content-Type": "application/json",
        },
      }));
      // console.log(configArray);
      try {
        let i = 0;
        while (i < configArray.length) {
          if (state.pauseDataTransfer) {
            // Pause data transfer if needed
            // eslint-disable-next-line
            await new Promise((resolve) => {
              let intervalId;

              const checkPauseStatus = () => {
                if (!state.pauseDataTransfer) {
                  clearInterval(intervalId);
                  resolve();
                }
              };

              intervalId = setInterval(checkPauseStatus, 1); // Check every 10 milliseconds
            });
          } else {
            commit("updateItemStatus", { index: i, statusData: 2 });

            try {
              // eslint-disable-next-line
              const response = await axios.request(configArray[i]);
              const { b2cguid } = response.data;
              const { b2bguid } = response.data;
              const { message } = response.data;
              const { status } = response.data;
              // console.log('status', status, b2bguid);
              if (status === "Ok") {
                commit("updateItemStatus", {
                  index: i,
                  statusData: 3,
                  b2cguid,
                  b2bguid,
                  message,
                });
                commit("updateGuid", { index: i, b2cguid, b2bguid });
                commit("completedItemsCount");
                commit("completedItemsDecrementCountDataSheet");
              } else {
                commit("updateItemStatus", {
                  index: i,
                  statusData: 4,
                  b2cguid: null,
                  b2bguid: null,
                  message,
                });
                commit("failedItemsCount");
                commit("completedItemsDecrementCountDataSheet");
              }
            } catch (e) {
              commit("updateItemStatus", {
                index: i,
                statusData: 4,
                b2cguid: null,
                b2bguid: null,
                message: "",
              });
              commit("failedItemsCount");
              commit("completedItemsDecrementCountDataSheet");
            }

            i += 1;
          }
        }
      } catch (error) {
        console.log(error);
        selectedItems.forEach((item, index) => {
          commit("updateItemStatus", { index, statusData: 4 });
        });
      }
      return null;
    },
  },

  mutations: {
    initialProducts(state, data) {
      // console.log('Data:', data);
      // console.log('Data keys:', Object.keys(data));
      if (data) {
        // console.log('Data:', data.remaining_hits);
        // console.log('Data:', data, state.upholsteries, state.leg_colors);
        // console.log('Data:', data.art_no);
        // state.selectedPage = 1;
        state.total_hits =
          data.totalData.total_hits != null ? data.totalData.total_hits : data.total_hits;
        state.remaining_hits =
          data.totalData.remaining_hits != null
            ? data.totalData.remaining_hits
            : data.remaining_hits;
        state.productsCollection =
          data.totalData.data != null ? data.totalData.data : data.totalData;
        state.productsFilteredCollection =
          data.totalData.data != null ? data.totalData.data : data.totalData;
        state.pages =
          data.totalData.total_hits != null
            ? Math.ceil(data.totalData.total_hits / state.productPerPage)
            : Math.ceil(data.total_hits / state.productPerPage);
        if (Array.isArray(state.productsCollection)) {
          state.products = state.productsCollection.slice(0, state.productPerPage);
        } else {
          state.products = [];
        }
        state.upholsteries = data.upholsteries != null ? data.upholsteries : null;
        state.leg_colors = data.leg_colors != null ? data.leg_colors : null;
        state.cont_upholsteries = data.cont_upholsteries != null ? data.cont_upholsteries : null;
        state.shell_colors = data.shell_colors != null ? data.shell_colors : null;
        state.collection_ids = data.collection_ids != null ? data.collection_ids : null;
        state.group_ids = data.group_ids != null ? data.group_ids : null;
        state.subgroup_ids = data.subgroup_ids != null ? data.subgroup_ids : null;
        state.category_ids = data.category_ids != null ? data.category_ids : null;
        state.config_groups = data.config_groups != null ? data.config_groups : null;
        state.searchTerm = data.art_no != null ? data.art_no : null;
      } else {
        console.error("Invalid data format. Expected an array of products.");
      }

      // if (state.selectedPage > 1 || state.searchTerm || state.upholsteries || state.leg_colors || state.cont_upholsteries || state.shell_colors || state.collection_ids || state.group_ids || state.subgroup_ids || state.category_ids) {
      //   state.productsCollection = data.totalData.data != null ? data.totalData.data : data.totalData;
      //   if (Array.isArray(state.productsCollection)) {
      //     state.searchedProducts = state.productsCollection.slice(0, state.productPerPage);
      //   } else {
      //     state.searchedProducts = [];
      //   }
      // }
      state.productsCollection = data.totalData.data != null ? data.totalData.data : data.totalData;
      state.cartProducts = data.cart_products != null ? data.cart_products : [];
      // console.log('state.cartProducts', state.cartProducts);
      // console.log('data', data);
      // console.log('data total', data.totalData);
      if (Array.isArray(state.productsCollection)) {
        state.searchedProducts.push(...state.productsCollection);
        if (state.cartProducts != null) {
          // console.log('initial', state.cartProducts);
          state.cartProducts.forEach((item) => {
            if (
              !state.searchedProducts.some(
                (cartProduct) =>
                  cartProduct.item_id === item.item_id &&
                  // && cartProduct.leg_item_id === item.leg_item_id
                  // && cartProduct.upholstery_item_id === item.upholstery_item_id
                  // && cartProduct.shell_item_id === item.shell_item_id
                  // && cartProduct.piping_and_cushion_item_id === item.piping_and_cushion_item_id
                  // && cartProduct.guid === item.guid
                  cartProduct.piping_and_cushion_nav_id === item.piping_and_cushion_nav_id &&
                  cartProduct.piping_and_cushion_supplier_id ===
                    item.piping_and_cushion_supplier_id &&
                  cartProduct.art_no === item.art_no &&
                  // && cartProduct.config_group_title === item.config_group_title
                  // && cartProduct.ean === item.ean
                  cartProduct.group_id === item.group_id &&
                  cartProduct.image === item.image &&
                  // && cartProduct.is_published === item.is_published
                  // && cartProduct.is_sent === item.is_sent
                  cartProduct.leg_nav_id === item.leg_nav_id &&
                  cartProduct.leg_supplier_id === item.leg_supplier_id &&
                  cartProduct.shell_nav_id === item.shell_nav_id &&
                  cartProduct.shell_supplier_id === item.shell_supplier_id &&
                  cartProduct.title === item.title &&
                  cartProduct.upholstery_nav_id === item.upholstery_nav_id &&
                  cartProduct.upholstery_supplier_id === item.upholstery_supplier_id
              )
            ) {
              state.searchedProducts.push(item);
            }
          });
          // console.log('final', state.cartProducts);
        }
      }
      // else {
      //   state.searchedProducts = [];
      // }
      // console.log('Data:', state.upholsteries, state.leg_colors);
      // console.log('Data:', state.remaining_hits);
      // console.log('Data:', state.productsCollection);
      // console.log('Data:', state.upholsteries);
    },
    pageChangeFeature(state, data) {
      state.selectedPage = data;
    },
    makeProductEmpty(state) {
      state.products = [];
    },
    setLoading(state, value) {
      state.isLoading = value;
    },
    selectedProductToSelectedArray(state, data) {
      const index = state.selectedItems.findIndex(
        (obj) =>
          obj.item_id === data.item_id &&
          obj.piping_and_cushion_nav_id === data.piping_and_cushion_nav_id &&
          obj.piping_and_cushion_supplier_id === data.piping_and_cushion_supplier_id &&
          obj.art_no === data.art_no &&
          obj.group_id === data.group_id &&
          obj.image === data.image
      );
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
        // if (state.selectedItems === null) {
        //   state.selectedItems = [];
        // }

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://configurator-api.normann-copenhagen.com/api/dash-board/delete-cart/?cart_id=${data.cart_id}`,
          headers: {
            "X-API-Key": "6548454121546548764564457464854546",
          },
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        state.selectedItems.push(data);
      }
    },
    updateItemStatus(state, { index, statusData, message }) {
      if (state.selectedItems[index]) {
        const selectIndex = state.sendItemsList.findIndex(
          (obj) =>
            obj.item_id === state.selectedItems[index].item_id &&
            obj.piping_and_cushion_nav_id ===
              state.selectedItems[index].piping_and_cushion_nav_id &&
            obj.piping_and_cushion_supplier_id ===
              state.selectedItems[index].piping_and_cushion_supplier_id &&
            obj.art_no === state.selectedItems[index].art_no &&
            obj.group_id === state.selectedItems[index].group_id &&
            obj.image === state.selectedItems[index].image
        );
        state.selectedItems[index].status = statusData;
        state.selectedItems[index].message = message;
        if (state.sendItemsList[selectIndex]) {
          state.sendItemsList[selectIndex].status = statusData;
          state.sendItemsList[selectIndex].message = message;
        }
      }
    },
    completedItemsCount(state) {
      state.completedItems += 1;
    },
    failedItemsCount(state) {
      state.failedItems += 1;
    },
    completedItemsDecrementCount(state) {
      if (state.completedItems + state.failedItems === state.sendItemsList.length) {
        state.showSendItemsList = true;
        state.showProgressPopup = false;
      }
    },
    completedItemsDecrementCountDataSheet(state) {
      if (state.completedItems + state.failedItems === state.sendItemsList.length) {
        state.showSendItemsListDataSheet = true;
        state.showProgressPopupDataSheet = false;
      }
    },
    addProductToSendItemsList(state, data) {
      const index = state.sendItemsList.findIndex(
        (obj) =>
          obj.item_id === data.item_id &&
          obj.piping_and_cushion_nav_id === data.piping_and_cushion_nav_id &&
          obj.piping_and_cushion_supplier_id === data.piping_and_cushion_supplier_id &&
          obj.art_no === data.art_no &&
          obj.group_id === data.group_id &&
          obj.image === data.image
      );
      if (index === -1) {
        state.sendItemsList.push(data);
      }
      // if (state.status === '4') {
      //   state.cartItems.push(data);
      // }
    },
    removeProductToSendItemsList(state, data) {
      const index = state.selectedItems.findIndex(
        (obj) =>
          obj.item_id === data.item_id &&
          obj.leg_item_id === data.leg_item_id &&
          obj.upholstery_item_id === data.upholstery_item_id &&
          obj.shell_item_id === data.shell_item_id &&
          obj.piping_and_cushion_item_id === data.piping_and_cushion_item_id &&
          obj.guid === data.guid
      );
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    clearOnNextProductCheck(state) {
      state.selectedItems = state.selectedItems
        .filter((item) => [1, 2, 4].includes(item.status))
        .map((item) => ({ ...item, status: 1 }));
      state.showSendItemsList = false;
      state.showSendItemsListDataSheet = false;
      state.sendItemsList = [];
      state.completedItems = 0;
      state.failedItems = 0;
    },
    showProgressPopup(state) {
      if (state.showProgressPopup) {
        state.showProgressPopup = false;
      } else {
        state.showProgressPopup = true;
      }
    },
    showProgressPopupDataSheet(state) {
      if (state.showProgressPopupDataSheet) {
        state.showProgressPopupDataSheet = false;
      } else {
        state.showProgressPopupDataSheet = true;
      }
    },
    hideProgressPopup(state) {
      state.showProgressPopup = false;
      state.showCancelPopup = false;
      state.sendItemsList = [];
    },
    hideProgressPopupDataSheet(state) {
      state.showProgressPopupDataSheet = false;
      state.showCancelPopup = false;
      state.sendItemsList = [];
    },
    showCancelPopup(state) {
      state.showCancelPopup = true;
      state.pauseDataTransfer = true;
    },
    hideCancelpopup(state) {
      state.showCancelPopup = false;
      state.pauseDataTransfer = false;
    },
    setFirstLetter(state, letter) {
      state.firstLetter = letter;
      localStorage.setItem("firstLetter", letter);
    },
    setEmail(state, userEmail) {
      state.email = userEmail;
      localStorage.setItem("Email", userEmail);
    },
    setAuthToken(state, authToken) {
      state.authToken = authToken;
    },
    setAuthRole(state, role) {
      state.authRole = role;
    },
    updateGuid(state, { index, b2cguid, b2bguid }) {
      state.sendItemsList[index].b2cguid = b2cguid;
      state.sendItemsList[index].b2bguid = b2bguid;
    },
    clearOnLogout(state) {
      state.productsCollection = [];
      state.productsFilteredCollection = [];
      state.products = [];
      state.pages = 0;
      state.productPerPage = 64;
      state.selectedPage = 1;
      state.selectedItems = [];
      state.searchTerm = null;
      state.upholsteries = null;
      state.leg_colors = null;
      state.cont_upholsteries = null;
      state.shell_colors = null;
      state.collection_ids = null;
      state.group_ids = null;
      state.subgroup_ids = null;
      state.category_ids = null;
      state.config_groups = null;
      state.filter = "";
      state.total_hits = null;
      state.remaining_hits = null;
      state.isLoading = false;
      state.user_id = null;
      state.item_id = null;
      state.leg_item_id = null;
      state.upholstery_item_id = null;
      state.shell_item_id = null;
      state.piping_and_cushion_item_id = null;
      state.guid = null;
      state.b2cguid = null;
      state.b2bguid = null;
      // state.cartItems = [];
      state.user_id_cart = null;
      state.item_id_cart = null;
      state.leg_item_id_cart = null;
      state.upholstery_item_id_cart = null;
      state.shell_item_id_cart = null;
      state.piping_and_cushion_item_id_cart = null;
      state.guid_cart = null;
      state.completedItems = 0;
      state.showSendItemsList = false;
      state.showSendItemsListDataSheet = false;
      state.sendItemsList = [];
      state.showProgressPopup = false;
      state.showProgressPopupDataSheet = false;
      state.showCancelPopup = false;
      state.firstLetter = "";
      state.pauseDataTransfer = false;
    },
    clearOnFilter(state) {
      // state.productsCollection = [];
      // state.productsFilteredCollection = [];
      // state.products = [];
      state.isLoading = true;
    },
    setSelected(state, value) {
      state.selected = value;
    },
    setFilter(state, value) {
      state.filter = value;
    },
    resetDropdown(state) {
      const [initialOption] = state.options[0];

      if (!state.selectedOption) {
        state.selectedOption = [];
      }

      state.selectedOption[0] = initialOption;
    },
    setSearchClicked(state, value) {
      state.searchClicked = value;
    },
    resetPauseDataTransfer(state) {
      state.pauseDataTransfer = false;
    },
    showSentItemList(state) {
      if (state.completedItems + state.failedItems > 0) {
        state.showSendItemsList = true;
      }
    },
    filterCompletedItems(state) {
      state.sendItemsList = state.sendItemsList.filter((item) => item.status === 3);
    },
    hideProgressPopupForCancel(state) {
      state.showProgressPopup = false;
      state.showCancelPopup = false;
    },
    hideProgressPopupDataSheetForCancel(state) {
      state.showProgressPopupDataSheet = false;
      state.showCancelPopup = false;
    },
    initializeSelectedItems(state, selectedItems) {
      state.selectedItems = selectedItems;
      // Update checked properties based on selectedItems
      state.products.forEach((product) => {
        this.checked = state.selectedItems.some((item) => item.item_id === product.item_id);
      });
    },
    SET_SELECTED_ITEMS(state, items) {
      state.selectedItems = items;
    },
    clearArtNo(state) {
      // console.log('mutation');
      state.searchTerm = "";
    },
  },
};

export default productState;
