<template>
    <div @click="closePopup" @keydown.enter="closePopup" tabindex="0">
      <div id="sentItemList" class="sent-item-list" v-if="$store.state.productState.showSendItemsListDataSheet">
        <div class="list-box-wrapper">
          <div class="list-box">
            <div class="list-box-head">
              <div class="list-head">
                <p>Sent items</p>
              </div>
              <div class="list-block-box">
                <div class="list-item">
                  <div class="slno">SL</div>
                  <div class="sku-number">SKU Number</div>
                  <div class="product-name">Upholstery Color</div>
                  <div class="sitecore-number">Image</div>
                  <!-- <div class="action">Action</div> -->
                  <div class="status">Status</div>
                </div>
              </div>
            </div>
            <div class="list-block-box list-items-single-wrapper">
              <div class="block-wrap" v-if="$store.state.productState.sendItemsList.length !== 0">
                <div class="list-item" v-for="(selectedItem, index) in $store.state.productState.sendItemsList"
                  :key="index">
                  <div class="slno" data-name="SL :">{{ index += 1 }}</div>
                  <div class="sku-number" data-name="SKUNumber :">{{ selectedItem.art_no }}</div>
                  <div class="product-name" data-name="ProductName :">{{ selectedItem.upholstery_supplier_id }}</div>
                  <div class="sitecore-number" data-name="SitecoreNumber :"><a :href="selectedItem.image">{{ selectedItem.image }}</a></div>
                  <div class="status" data-name="Status :">
                    <span>{{ selectedItem.message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="list-item-footer-links">
              <a href="" @click.prevent="downloadExcel" @keydown.enter="downloadExcel" tabindex="0">Download Excel</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
// eslint-disable-next-line
  import * as XLSX from 'xlsx';

export default {
  name: 'sentToDataSheet',
  data() {
    return {
      showPopup: true,
      copied: false,
    };
  },
  methods: {
    closePopup(event) {
      if (event.target.id === 'sentItemList') {
        const parentDiv = event.target.parentNode;
        parentDiv.classList.remove('open');
        this.$store.commit('clearOnNextProductCheck');

        this.$store.dispatch('productInitialList', {
          searchKeyword: this.$store.state.productState.searchTerm,
          selectOptionFour: this.$store.state.productState.collection_ids && this.$store.state.productState.collection_ids.length > 0 ? this.$store.state.productState.collection_ids : [],
          selectOptionFive: this.$store.state.productState.group_ids && this.$store.state.productState.group_ids.length > 0 ? this.$store.state.productState.group_ids : [],
          selectOptionSix: this.$store.state.productState.subgroup_ids && this.$store.state.productState.subgroup_ids.length > 0 ? this.$store.state.productState.subgroup_ids : [],
          selectOptionSeven: this.$store.state.productState.category_ids && this.$store.state.productState.category_ids.length > 0 ? this.$store.state.productState.category_ids : [],
          selectOptionThree: this.$store.state.productState.shell_colors && this.$store.state.productState.shell_colors.length > 0 ? this.$store.state.productState.shell_colors : [],
          selectOptionOne: this.$store.state.productState.leg_colors && this.$store.state.productState.leg_colors.length > 0 ? this.$store.state.productState.leg_colors : [],
          selectOptionZero: this.$store.state.productState.upholsteries && this.$store.state.productState.upholsteries.length > 0 ? this.$store.state.productState.upholsteries : [],
          selectOptionTwo: this.$store.state.productState.cont_upholsteries && this.$store.state.productState.cont_upholsteries.length > 0 ? this.$store.state.productState.cont_upholsteries : [],
          selectOptionEight: this.$store.state.productState.config_groups && this.$store.state.productState.config_groups.length > 0 ? this.$store.state.productState.config_groups : [],
          selectedPage: this.$store.state.productState.selectedPage,
        });
        this.$store.commit('pageChangeFeature', this.$store.state.productState.selectedPage);
        this.showPopup = false;
        if (this.$store.state.productState.selectedItems.length === 0) {
          document.querySelector('.cartview').classList.remove('cart-open');
          document.body.classList.remove('o-hidden');
        }
        localStorage.setItem('cartItems', JSON.stringify(this.$store.state.productState.selectedItems));
      }
    },
  },
};
</script>
  <style scoped lang="scss">
  .sent-item-list {
    display: none;
    // display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(88, 88, 88, 0.75);
    backdrop-filter: blur(4px);
    padding: 15px;
    z-index: 999;

    .list-box-wrapper {
      width: 1020px;
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      padding: 0px 0px 0px;
      background: #fff;
      /* max-width: 100%; */
    }

    .list-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;

      max-height: 100vh;
      overflow: hidden;

    }

    .list-box-head {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;
      background: #fafafa;
      backdrop-filter: blur(4px);
      padding-top: 21px;
      border-bottom: 1px solid #E9EDF5;

      @media(max-width:768px) {
        padding-bottom: 20px;
      }

      .list-block-box {
        margin-bottom: 0px;
        margin-top: 10px;

        @media(max-width:768px) {
          display: none;
        }

        div {
          color: #000;
          font-size: 14px;
          font-family: "gill_sans_mtregular";
          line-height: 20px;
          font-weight: 600;
        }
      }

    }

    .list-head {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;
      padding: 0px 40px;

      p {
        color: #000;
        font-size: 18px;
        font-family: "gill_sans_mtregular";
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 0px;
      }
    }

    .list-block-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;
      margin: 0px 0px 0px;

      &.list-items-single-wrapper {
        max-height: calc(82vh - 171px);
        overflow-y: auto;
        padding-bottom: 56px;

        @media(max-width:768px) {
          max-height: calc(100vh - 171px);
        }

        .block-wrap {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: 0 0 0px grey;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #7A7A7A;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #FCFCFC;
        }
      }
    }

    .list-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 100%;
      position: relative;
      padding: 22px 30px;

      &:nth-child(even) {
        background: #f9fafc;
      }

      div {
        @media(max-width:768px) {
          width: 100% !important;
          margin-bottom: 10px;
          font-size: 14px !important;

          &:before {
            margin-right: 5px;
            color: #000;
            font-weight: 600;
          }
        }
      }

      .slno {
        width: 5%;
        display: block;
        position: relative;
        color: #0a0a0a;
        font-size: 14px;
        font-family: "gill_sans_mtregular";
        line-height: 18px;
        letter-spacing: 0.36px;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }
      }

      .sku-number {
        width: 12%;
        display: block;
        position: relative;
        color: #0a0a0a;
        font-size: 14px;
        font-family: "gill_sans_mtregular";
        line-height: 18px;
        letter-spacing: 0.36px;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }
      }

      .product-name {
        width: 25%;
        display: block;
        position: relative;
        font-size: 14px;
        font-family: "gill_sans_mtregular";
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.42px;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }
      }

      .sitecore-number {
        width: 45%;
        font-size: 14px;
        font-family: "gill_sans_mtregular";
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.42px;
        color: #8A4B4B;
        position: relative;
        word-break: break-word;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }

        span {
          color: #000;
        }
      }

      .status {
        width: 13%;
        margin-left: auto;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }

        span {
          font-size: 14px;
          font-family: "gill_sans_mtregular";
          font-weight: 300;
          line-height: 18px;
          letter-spacing: 0.42px;
        }

        .success {
          color: green;
        }

        .failed {
          color: red;
        }
      }

      .action {
        width: 15%;
        color: #000;
        font-size: 14px;
        font-family: "gill_sans_mtregular";
        line-height: 20px;

        &:before {
          @media(max-width:768px) {
            content: attr(data-name);
          }
        }
      }

      .slno,
      .product-name,
      .sitecore-number,
      .action,
      .sku-number,
      .status {
        padding: 0px 10px;
      }
    }

    .list-item-footer-links {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      position: relative;
      padding: 20px;
      justify-content: flex-end;
      background: rgba(244, 247, 252, 0.75);
      backdrop-filter: blur(4px);
      position: absolute;
      bottom: 0;

      a {
        width: auto;
        display: block;
        text-decoration: none;
        color: #00733B;
        font-size: 11px;
        font-family: "gill_sans_mtregular";
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
      }
    }

    span.copy {
      display: inline-flex;
      flex-wrap: wrap;
      width: auto;
      max-width: 100%;
      // border: 1px solid #ccc;
      // border-radius: 50px;
      // padding: 5px 18px;
      cursor: pointer;

      img {
        padding-left: 0;
        width: 18px;
        position: relative;
        top: 3px;
        left: 3px;
      }
    }

    .copied {
      position: absolute;
      top: 50%;
      background-color: #000000;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .sent-item-list-wrapper-DataSheet.open {
    .sent-item-list {
      display: flex;
      animation: fade-in 0.3s ease-in-out;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }</style>
