<template>
  <div class="login-slider" style="background-image: url(../assets/Slice-bench.png);">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <!-- Slides go here -->
        <div class="swiper-slide" v-for="slide in slides" :key="slide.id">
          <div class="slider-col">
            <div class="content">
              <h2 v-html="slide.content"></h2>
              <p>{{ slide.para }}</p>
              <img :alt="slide.logoalt" :src="slide.logo">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-wrapper">
        <div class="swiper-button-prev">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9131 12.875L14.7058 9.08112C14.9013 8.87877 15.0094 8.60775 15.007
                  8.32643C15.0045 8.04512 14.8917 7.77602 14.6927 7.57709C14.4938 7.37816 14.2247
                  7.26532 13.9434 7.26288C13.6621 7.26044 13.3911 7.36858 13.1887 7.56402L8.63633
                  12.1164C8.43519 12.3176 8.32219 12.5905 8.32219 12.875C8.32219 13.1595 8.43519
                  13.4323 8.63633 13.6335L13.1887 18.1859C13.3911 18.3813 13.6621 18.4895 13.9434
                  18.487C14.2247 18.4846 14.4938 18.3718 14.6927 18.1728C14.8917 17.9739 15.0045
                  17.7048 15.007 17.4235C15.0094 17.1422 14.9013 16.8711 14.7058 16.6688L10.9131
                  12.875ZM12.8743 2.14579C18.8001 2.14579 23.6035 6.94924 23.6035 12.875C23.6035
                  18.8007 18.8001 23.6041 12.8743 23.6041C6.94863 23.6041 2.14518 18.8007 2.14518
                  12.875C2.14518 6.94924 6.94863 2.14579 12.8743 2.14579Z" fill="#ffffff" />
          </svg>

        </div>
        <div class="swiper-button-next">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8369 12.875L11.0442 16.6689C10.8487 16.8712 10.7406
                  17.1423 10.743 17.4236C10.7455 17.7049 10.8583 17.974 11.0573 18.1729C11.2562
                  18.3718 11.5253 18.4847 11.8066 18.4871C12.0879 18.4896 12.3589 18.3814 12.5613
                  18.186L17.1137 13.6336C17.3148 13.4324 17.4278 13.1595 17.4278 12.875C17.4278
                  12.5905 17.3148 12.3177 17.1137 12.1165L12.5613 7.5641C12.3589 7.36866 12.0879
                  7.26052 11.8066 7.26296C11.5253 7.26541 11.2562 7.37825 11.0573 7.57717C10.8583
                  7.7761 10.7455 8.0452 10.743 8.32652C10.7406 8.60783 10.8487 8.87885 11.0442
                  9.08121L14.8369 12.875ZM12.8757 23.6042C6.94993 23.6042 2.14648 18.8008 2.14648
                  12.875C2.14648 6.94932 6.94993 2.14587 12.8757 2.14587C18.8014 2.14587 23.6048
                  6.94932 23.6048 12.875C23.6048 18.8008 18.8014 23.6042 12.8757 23.6042Z" fill="#ffffff" />
          </svg>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.css';
import 'swiper/swiper-bundle.css';
import Swiper, { Navigation, Autoplay, EffectFade } from 'swiper';

export default {
  data() {
    return {
      slides: [
        {
          id: 1,
          image: 'Slide_1.jpg',
          content: 'Designer furniture that plays up natural materials <span style="color: #000;">with us</span>',
          para: 'Warming colors and has a simple, Scandinavian feel.',
          alt: 'Slide one',
          logo: '../assets/logo.png',
          logoalt: 'logo',
        },
        // {
        //   id: 2,
        //   image: 'Slide_2.jpg',
        //   content: 'Slide 2 content',
        //   para: 'Warming colors and has a simple, Scandinavian feel.',
        //   alt: 'Slide two',
        // },
        // {
        //   id: 3,
        //   image: 'Slide_3.jpg',
        //   content: 'Slide 3 content',
        //   para: 'Warming colors and has a simple, Scandinavian feel.',
        //   alt: 'Slide three',
        // },
        // {
        //   id: 4,
        //   image: 'Slide_4.jpg',
        //   content: 'Slide 4 content',
        //   para: 'Warming colors and has a simple, Scandinavian feel.',
        //   alt: 'Slide four',
        // },
      ],
    };
  },
  mounted() {
    // eslint-disable-next-line
    new Swiper('.login-slider .swiper-container', {
      modules: [Navigation, Autoplay, EffectFade],
      effect: 'fade',
      fadeEffect: { crossFade: true },
      slidesPerView: 1,
      speed: 1000,
      autoHeight: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  },
};
</script>

<style scoped lang="scss">
.login-slider {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;

  .swiper-container {
    width: 100%;
    position: relative;
    margin-bottom: 240px;

    @media(max-width: 991px) {
        margin: 60px 0;
    }

    .swiper-slide {
      opacity: 0;
      transition: opacity 0.5s ease-out;

      .content {
        display: none;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      transform: none;

      svg {
        path {
          fill: #1C1C1C;
        }
      }
    }

    .swiper-button-disabled {
      opacity: 1;

      svg {
        path {
          fill: #ffffff;
          fill-opacity: 0.5;
        }
      }
    }

    .swiper-slide-active {
      opacity: 1;

      .content {
        display: block;
        animation: slideUp 0.8s ease-in-out;
      }
    }

    .swiper-button-wrapper {
      display: flex;
      max-width: 455px;
      margin: 42px auto 0;
      padding: 0 30px;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      display: none;
    }

    .slider-col {
      position: relative;
      height: 100%;
    }

    .content {
      z-index: 9;
      max-width: 455px;
      margin: 0 auto;
      width: 100%;
      padding: 0 30px 0 56px;

      h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 136%;
        letter-spacing: 0.3px;
        color: #757575;
        margin-bottom: 2px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 229%;
        letter-spacing: 0.3px;
        color: #000000;
        margin-bottom: 8px;
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translateY(0, 0);
  }
}
</style>
