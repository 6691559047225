<template>
  <div id="app">
    <div class="login-page-wrapper">
      <div class="left-side">
        <div class="logo">
          <a href="/"><img alt="Normann logo" src="../assets/logo.png" /></a>
        </div>
        <Login heading="Login" />
      </div>
      <div class="right-side">
        <LoginSlider />
      </div>
    </div>
  </div>
</template>

<script>
import Login from "../components/Login.vue";
import LoginSlider from "../components/LoginSlider.vue";

export default {
  name: "App",
  components: {
    Login,
    LoginSlider,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "bodoni_stdregular";
  src: url("@/assets/fonts/bodonistd-webfont.woff2") format("woff2"),
    url("@/assets/fonts/bodonistd-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bodoni_stdbold";
  src: url("@/assets/fonts/bodonistd-bold-webfont.woff2") format("woff2"),
    url("@/assets/fonts/bodonistd-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gill_sans_mtregular";
  src: url("@/assets/fonts/gillsansmt-webfont.woff2") format("woff2"),
    url("@/assets/fonts/gillsansmt-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gill_sans_mtbold";
  src: url("@/assets/fonts/gillsansmt-bold-webfont.woff2") format("woff2"),
    url("@/assets/fonts/gillsansmt-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

#app {
  font-family: "gill_sans_mtregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  min-height: 100vh;
}

.login-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  .logo {
    text-align: center;
    padding-top: 202px;
    @media (max-width: 1199px) {
      padding-top: 75px;
    }
  }

  .left-side {
    width: calc(100% - 38.8%);
    padding: 0 20px 100px;
    @media (max-width: 1199px) {
      width: 50%;
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .right-side {
    width: 38.8%;
    @media (max-width: 1199px) {
      width: 50%;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.btn-wrap {
  button {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    background-color: #000000;
    border: 0;
    min-width: 245px;
    min-height: 65px;
    padding: 15px;
  }
}
</style>
