<template>
  <div>
    <header>
      <div class="container-row">
        <div class="header-wrap">
          <div class="left-side">
            <div class="logo">
              <a href="/dashboard"><img alt="Normann logo" src="../assets/logo.svg" /></a>
            </div>
          </div>
          <div class="right-side">
            <div
              v-if="!isAdminRoute"
              class="selected-items"
              @click="openPopup"
              @keydown.enter="openPopup"
              tabindex="0"
            >
              <span class="count-tag">Selected items</span>
              <span class="count" :class="{ 'count-increase': increaseCount }">
                <span class="count-number">{{
                  $store.state.productState.selectedItems.length
                }}</span>
              </span>
            </div>
            <div class="profile-badge_wrap">
              <div
                class="profile-badge"
                @click.prevent="openDropDown"
                @keydown.enter="openDropDown"
                tabindex="0"
              >
                {{ $store.state.productState.firstLetter }}
              </div>
              <div class="profile-dropDown" :class="{ open: showDropDown }">
                <ul>
                  <li @click="logout" @keydown.enter="logout" tabindex="0">
                    <span class="icon"
                      ><img src="../assets/log-out.svg" alt="Profile image"
                    /></span>
                    <span class="content">Logout</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <cartView
      :onCartItemsDeleted="handleCartItemsDeleted"
      :visible="showPopup"
      @close="cartClose"
    />
  </div>
</template>
<script>
// import { computed } from 'vue';
// import { store } from 'vuex';
import Cookies from "js-cookie";

import cartView from "./cartView.vue";

export default {
  name: "HeaderNav",
  components: {
    cartView,
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      showPopup: false,
      showDropDown: false,
      increaseCount: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    "$store.state.productState.selectedItems.length": function (newLength) {
      if (newLength === 0) {
        document.body.classList.remove("o-hidden");
      }

      // Your existing code for handling count increase animation
      this.increaseCount = true;
      setTimeout(() => {
        this.increaseCount = false;
      }, 500); // Adjust the duration to match your CSS transition time
    },
  },
  methods: {
    openPopup() {
      if (this.$store.state.productState.selectedItems.length > 0) {
        this.showPopup = true;
        document.body.classList.add("o-hidden");
        document.querySelector(".cartview").classList.add("cart-open");
      }
    },
    cartClose() {
      this.showPopup = false;
      document.body.classList.remove("o-hidden");
    },
    handleCartItemsDeleted() {
      this.showPopup = false;
      document.body.classList.remove("o-hidden");
    },
    openDropDown() {
      // Close the dropdown if it's already open (to toggle it)
      if (this.showDropDown) {
        this.showDropDown = false;
        return;
      }

      // Open the dropdown
      this.showDropDown = true;
    },
    closeDropDown(event) {
      // Check if the click occurred outside the profile badge and dropdown
      const profileBadgeElement = this.$el.querySelector(".profile-badge");
      const profileDropDownElement = this.$el.querySelector(".profile-dropDown");
      if (
        !profileBadgeElement.contains(event.target) &&
        !profileDropDownElement.contains(event.target)
      ) {
        this.showDropDown = false;
      }
    },
    logout() {
      Cookies.remove("email");
      Cookies.remove("user_id");
      this.$router.push("/login");
      this.$store.commit("clearOnLogout");
      localStorage.clear();
    },
  },
  mounted() {
    document.body.addEventListener("click", this.closeDropDown);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeDropDown);
    localStorage.clear();
  },
  computed: {
    isAdminRoute() {
      return this.$route.path === "/admin";
    },
  },
};
</script>

<style scoped lang="scss">
header {
  background-color: #000;
  padding: 30px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  left: 0;
}

.header-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo {
  line-height: 0;
}

.right-side {
  display: flex;
}

.selected-items {
  display: flex;
  align-items: center;
  cursor: pointer;

  .count {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;

    &-tag {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin-right: 9px;
      @media (max-width: 475px) {
        display: none;
      }
    }
  }
}

.profile-badge {
  width: 38.97px;
  height: 38.97px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 13px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #000;

  &_wrap {
    position: relative;
  }
}

.profile-dropDown {
  position: absolute;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
  min-width: 200px;
  right: 0;
  top: calc(100% + 16px);
  z-index: 9;
  display: none;

  &.open {
    display: block;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top: -8px;
    right: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .content {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span {
    display: inline-block;
  }

  li {
    display: flex;
    align-items: center;
    padding: 11px 11px;
    cursor: pointer;
  }

  .icon {
    line-height: 0;
    margin-right: 5px;
  }
}

.count-increase {
  .count-number {
    animation-name: shake-vertical;
    will-change: transform;
    animation-duration: 0.6s;
    animation-fill-mode: both;
  }
}

@keyframes shake-vertical {
  6% {
    transform: translateY(-0.5rem);
  }

  15% {
    transform: translateY(-0.5rem);
  }

  30% {
    transform: translateY(0.5rem);
  }

  45% {
    transform: translateY(-0.5rem);
  }

  60% {
    transform: translateY(0.5rem);
  }
}
</style>
