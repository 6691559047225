<template>
  <div id="dashboard">
    <HeaderNav/>
    <div class="dashboard">
      <div class="container-row">
        <div class="dashboard-wrap">
          <div class="left-side">
            <FilterSection />
          </div>
          <div class="right-side">
            <ProductPage />
          </div>
        </div>
      </div>
    </div>
    <!-- <cartView/>
    <cancelPopup />
    <productPopup />
    <sentItemList />
    <progressPopup /> -->
  </div>
</template>
<script>
import HeaderNav from '../components/Header.vue';
import FilterSection from '../components/Filter.vue';
// import FilterMultiselect from '../components/FilterMultiselect.vue';
import ProductPage from '../components/Products.vue';
// import cartView from '../components/cartView.vue';
// import cancelPopup from '../components/cancelPopup.vue';
// import productPopup from '../components/productPopup.vue';
// import sentItemList from '../components/sentItemList.vue';
// import progressPopup from '../components/progressPopup.vue';

export default {
  name: 'DashBoard',
  components: {
    HeaderNav,
    FilterSection,
    ProductPage,
    // cartView,
    // cancelPopup,
    // productPopup,
    // sentItemList,
    // progressPopup,
    // FilterMultiselect,
  },
};
</script>

<style lang="scss">
.o-hidden {
  overflow: hidden;
  position: relative;
}
</style>
