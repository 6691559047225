import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import App from "./App.vue";
import store from "./store";
import Login from "./views/Login.vue";
import Admin from "./views/Admin.vue";
import DashBoard from "./views/Dashboard.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashBoard,
    meta: { requiresAuth: true },
  },
  {
    path: "/:catchAll(.*)", // Match any undefined route
    component: () => import("./views/notFound.vue"), // Import the 404 component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const requiresAdmin = to.matched.some((route) => route.meta.requiresAdmin);
  const authToken = Cookies.get("email");
  const authRole = Cookies.get("user_role");

  if (requiresAuth && !authToken) {
    // Redirect to login if authentication is required but no token is present
    next("/login");
  } else if (authToken && (to.path === "/login" || to.path === "/")) {
    // Redirect to dashboard if already authenticated and trying to access login or root
    next("/dashboard");
  } else if (!authToken && to.path === "/") {
    // Redirect to login if not authenticated and accessing the root path
    next("/login");
  } else if (requiresAdmin && authRole !== "1") {
    // Redirect to login page if user is not an admin
    next("/dashboard");
  } else {
    if (authToken) {
      store.commit("setAuthToken", authToken);
    }
    if (authRole) {
      store.commit("setAuthRole", authRole);
    }
    next();
  }
});

export default router;

const app = createApp(App);
app.use(router);
app.use(store);

app.mixin({
  created() {
    const firstLetter = localStorage.getItem("firstLetter");
    if (firstLetter) {
      this.$store.commit("setFirstLetter", firstLetter);
    }
  },
});

app.mount("#app");
